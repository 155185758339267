import { Vec3d } from 'app/ts/Interface_DTO_Draw';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

/**
 * Loads an .obj file with associated .mtl files.
 * @param modelPath Should be either with .xnb extension, or without any extension
 * @param targetSize If specified, will resize the model to these dimensions.
 * @param originalSize If specified, will be used as the resize basis.
 * These values will be overridden if they exist in the .obj file (see README.md #KA:EffectiveSize).
 * If they are not supplied one way or another, model will not be resized.
 * If a dimension is missing or non-positive, that dimension will not be resized.
 */
export async function loadObj(
  modelPath: string,
  targetSize?: Partial<Vec3d>,
  originalSize?: Partial<Vec3d>,
): Promise<THREE.Object3D> {
  const basePath = '/StaticAssets/';
  //remove .xnb from path if it exists
  const newPath = /^(.*?)(\.xnb)?$/.exec(modelPath);
  const extensionLessPath = basePath + (newPath ? newPath[1] : modelPath);

  const materialPromise = getMtl(extensionLessPath);
  let result = await getObj(
    extensionLessPath,
    materialPromise,
    targetSize,
    originalSize,
  );
  return result;
}

async function getMtl(
  extensionLessPath: string,
): Promise<MTLLoader.MaterialCreator> {
  const path = extensionLessPath + '.mtl';
  const response = await fetch(path);
  if (!response.ok) {
    throw new Error(
      `Could not load mtl file '${path}': ${response.status} ${response.statusText}`,
    );
  }
  const text = await response.text();
  const loader = new MTLLoader();
  const materials = loader.parse(text, path);
  return materials;
}

async function getObj(
  extensionLessPath: string,
  materialPromise: Promise<MTLLoader.MaterialCreator>,
  targetSize?: Partial<Vec3d>,
  originalSize?: Partial<Vec3d>,
) {
  const path = extensionLessPath + '.obj';
  const response = await fetch(path);
  if (!response.ok) {
    throw new Error(
      `Could not load .obj file '${path}': ${response.status} ${response.statusText}`,
    );
  }
  let text = await response.text();
  const allLines = parseObj(text);

  console.groupCollapsed('obj file transformation', {
    path,
    targetSize,
    originalSize,
  });
  console.time('obj file transformation');
  let isChanged = processLines(allLines, targetSize, originalSize);

  if (isChanged) {
    text = allLines.map((l) => l.toString()).join('\n');
    console.groupCollapsed('result');
    console.debug(text);
    console.groupEnd();
  }

  console.timeEnd('obj file transformation');
  console.groupEnd();

  const loader = new OBJLoader();
  loader.materials = await materialPromise;
  const obj = loader.parse(text);
  obj.castShadow = true;
  obj.receiveShadow = true;
  return obj;
}

function processLines(
  allLines: ObjLine[],
  targetSize?: Partial<Vec3d>,
  originalSize?: Partial<Vec3d>,
): boolean {
  let isChanged = false;
  const verticies = allLines.filter((l) => l instanceof Vertex) as Vertex[];

  // move zero-point (EffectiveZero)
  const effectiveZero = allLines.find((l) => l instanceof EffectiveZero) as
    | EffectiveZero
    | undefined;
  if (effectiveZero) {
    for (let dim of dimensions) {
      const newZero = effectiveZero[dim];
      if (newZero === 0) continue;
      for (let v of verticies) {
        v[dim] += newZero;
      }
      isChanged = true;
      console.debug(`Moved EffectiveZero dim ${dim} ${newZero}`);
    }
  }

  // resize
  let effectiveSize = allLines.find((l) => l instanceof EffectiveSize) as
    | Partial<Vec3d>
    | undefined;
  if (!effectiveSize) {
    effectiveSize = originalSize;
  }
  if (!effectiveSize) {
    //Not able to resize
    return isChanged;
  }
  const amountToStretch = { X: 0, Y: 0, Z: 0 };
  for (let dim of dimensions) {
    const original = effectiveSize?.[dim];
    if (!original) continue;
    const newSize = targetSize?.[dim];
    if (!newSize) continue;
    amountToStretch[dim] = newSize - original;
  }
  const unstretchableRegions = allLines.filter(
    (l) => l instanceof UnstretchableRegion,
  ) as UnstretchableRegion[];
  const isStretched = stretchObjPoints(
    verticies,
    amountToStretch,
    unstretchableRegions,
  );
  isChanged ||= isStretched;

  return isChanged;
}

function stretchObjPoints(
  verticies: Vertex[],
  amountToStretch: Vec3d,
  unstretchableRegions: UnstretchableRegion[],
): boolean {
  if (!dimensions.some((dim) => amountToStretch[dim])) {
    return false;
  }

  const minPosition = {
    X: Infinity,
    Y: Infinity,
    Z: Infinity,
  };
  const maxPosition = {
    X: -Infinity,
    Y: -Infinity,
    Z: -Infinity,
  };
  for (let vertex of verticies) {
    for (let dimension of dimensions) {
      minPosition[dimension] = Math.min(
        minPosition[dimension],
        vertex[dimension],
      );
      maxPosition[dimension] = Math.max(
        maxPosition[dimension],
        vertex[dimension],
      );
    }
  }
  for (let dim of dimensions) {
    const amountToStretchDim = amountToStretch[dim];
    if (amountToStretchDim === 0) {
      //no need to change anything in this dimension
      continue;
    }
    const unstrechableRegionsDim = unstretchableRegions.filter(
      (r) => r.dimension === dim,
    );

    let stretchableSpace = maxPosition[dim] - minPosition[dim];
    let lastIntervalEnd = -Infinity;
    for (let region of unstrechableRegionsDim) {
      if (region.start < lastIntervalEnd) {
        throw new Error(
          `Interval ${region} in dimension ${dim} started before last interval ended`,
        );
      }
      lastIntervalEnd = region.end;
      const intervalStart = Math.max(minPosition[dim], region.start);
      const intervalEnd = Math.min(maxPosition[dim], region.end);
      const intervalSize = intervalEnd - intervalStart;
      stretchableSpace -= intervalSize;
    }
    if (stretchableSpace <= 0) {
      throw new Error(`No stretchable space in dimension ${dim}`);
    }

    for (let vertex of verticies) {
      if (vertex[dim] < 0) {
        let stretchableSpaceBefore0 = -vertex[dim];
        for (let interval of unstrechableRegionsDim) {
          if (interval.end < vertex[dim]) continue;
          if (interval.start >= 0) break;
          const effectiveIntervalStart = Math.max(interval.start, vertex[dim]);
          const effectiveIntervalEnd = Math.min(
            interval.end,
            maxPosition[dim],
            0,
          );
          const intervalSize = effectiveIntervalEnd - effectiveIntervalStart;
          stretchableSpaceBefore0 -= intervalSize;
        }

        const stretchAmount =
          amountToStretchDim * (stretchableSpaceBefore0 / stretchableSpace);
        vertex[dim] -= stretchAmount;
      } else {
        // line[dim] > 0

        let stretchableSpaceAfter0 = vertex[dim];
        for (let interval of unstrechableRegionsDim) {
          if (interval.end < 0) continue;
          if (interval.start > vertex[dim]) break;
          let effectiveIntervalStart = Math.max(
            0,
            interval.start,
            minPosition[dim],
          );
          let effectiveIntervalEnd = Math.min(interval.end, vertex[dim]);
          let intervalSize = effectiveIntervalEnd - effectiveIntervalStart;
          stretchableSpaceAfter0 -= intervalSize;
        }

        const stretchAmount =
          amountToStretchDim * (stretchableSpaceAfter0 / stretchableSpace);
        vertex[dim] += stretchAmount;
      }
    }
    if (stretchableSpace !== 0)
      console.debug(
        `Stretched object dim ${dim} ${amountToStretchDim} mm (${(amountToStretchDim / stretchableSpace) * 100} %)`,
      );
  }
  return true; //isChanged
}

function parseObj(obj: string): ObjLine[] {
  const lines = obj.split('\n');

  return lines.map((line, lineIndex) => parseObjLine(line, lineIndex));
}

function parseObjLine(line: string, lineIndex: number): ObjLine {
  let result: ObjLine;
  try {
    result =
      Vertex.parse(line) ??
      EffectiveSize.parse(line) ??
      EffectiveZero.parse(line) ??
      UnstretchableRegion.parse(line) ??
      line; // this line is not one we care about.
    return result;
  } catch (ex) {
    throw new Error(`Error parsing obj file in line ${lineIndex + 1}: `, {
      cause: ex,
    });
  }
}

interface XYZLine extends Vec3d {
  X: number;
  Y: number;
  Z: number;
  readonly trailingString: string;
  toString(): string;
}
interface XYZLineStatic<T extends string> {
  new (x: number, y: number, z: number, trailingString: string): XYZLine;
  readonly linePrefix: T;
  parse(line: string): XYZLine | undefined;
}

function createLineClass<T extends string>(linePrefix: T) {
  let result: XYZLineStatic<T>;
  result = class {
    public static linePrefix: T = linePrefix;
    public constructor(
      public X: number,
      public Y: number,
      public Z: number,
      public readonly trailingString: string,
    ) {}
    public toString() {
      return `${result.linePrefix} ${this.X} ${this.Y} ${this.Z}${this.trailingString}`;
    }

    public static parse(line: string) {
      const regex = new RegExp(
        '^\\s*' + // start of line
          linePrefix +
          '\\s+' + // whitespace
          '(-?[0-9\\.]+)' + // 1st number
          '\\s+' + // whitespace
          '(-?[0-9\\.]+)' + // 2nd number
          '\\s+' + // whitespace
          '(-?[0-9\\.]+)' + // 3rd number
          '(.*)', //trailing text
      );
      let match = regex.exec(line);
      if (!match) return undefined;
      return new result(
        parseFloat(match[1]),
        parseFloat(match[2]),
        parseFloat(match[3]),
        match[4],
      );
    }
  };
  return result;
}

const Vertex = createLineClass('v');
type Vertex = XYZLine;
const EffectiveSize = createLineClass('#KA:EffectiveSize');
type EffectiveSize = XYZLine;
const EffectiveZero = createLineClass('#KA:EffectiveZero');
type EffectiveZero = XYZLine;

class UnstretchableRegion {
  public static parse(line: string): UnstretchableRegion | undefined {
    const regex =
      /^\s*#KA:UnstretchableRegion\s+([XYZxyz])\s+(-?[0-9\.]+)\s+(-?[0-9\.]+)(.*)/;
    const match = regex.exec(line);
    if (!match) return undefined;
    return new UnstretchableRegion(
      match[1].toUpperCase() as Dimension,
      parseFloat(match[2]),
      parseFloat(match[3]),
      match[4],
    );
  }

  constructor(
    public readonly dimension: Dimension,
    public readonly start: number,
    public readonly length: number,
    public readonly trailingString: string,
  ) {
    if (length <= 0) throw new Error('Unstretchable region must be > 0');
  }
  public get end(): number {
    return this.start + this.length;
  }

  public toString(): string {
    return `#KA:UnstretchableRegion ${this.dimension} ${this.start} ${this.length}${this.trailingString}`;
  }
}

type ObjLine = XYZLine | UnstretchableRegion | string;
type Dimension = keyof Vec3d;
const dimensions = ['X', 'Y', 'Z'] as Dimension[];
