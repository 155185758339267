import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PartitionModule } from 'app/partition/partition.module';
import { CornerNameBubbleModule } from 'app/ts/viewmodels/components/corner-name-bubble.module';
import { DarkSideBarModule } from 'app/ts/viewmodels/components/dark-sidebar-module';
import { ProductListModule } from 'app/ts/viewmodels/components/product-list.module';
import { TopbarModule } from 'app/ts/viewmodels/components/topbar.module';
import { ErrorInfoModule } from 'app/ts/viewmodels/error-info.module';
import { BackingComponent } from './backing.component';
import { BackingImageVm } from './BackingImageVm';
import { CorpusVm } from './CorpusVm';
import { D3DisplayVm } from './D3DisplayVm';
import { D3Vm } from './D3Vm';
import { DoorImageVm } from './DoorImageVm';
import { DoorVm } from './DoorVm';
import { FloorPlanPropertySheetComponent } from './floor-plan-property-sheet.component';
import { FloorPlanImageVm } from './FloorPlanImageVm';
import { FloorPlanVm } from './FloorPlanVm';
import { Interior2dVm } from './Interior2dVm';
import { InteriorVm } from './InteriorVm';
import { PickableModule } from './pickable.module';
import { SwingImageVm } from './SwingImageVm';
import { SwingVm } from './SwingVm';
import { ValidationVm } from './ValidationVm';
import { SelectedItemsService } from './selected-items.service';
import { ItemEditorVm } from './ItemEditorVm';
import { DragInfoService } from './drag-info.service';
import { FillingsVm } from './fillings/FillingsVm';
import { FillingsImageVm } from './fillings/FillingsImageVm';
import { FillingPropertySheetComponent } from './fillings/filling-property-sheet/filling-property-sheet.component';
import { I18NModule } from '../i18n/i18n.module';
import { BarPropertySheetComponent } from './fillings/bar-property-sheet/bar-property-sheet.component';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { PartitionPlanDataService } from 'app/partition/partition-plan-data.service';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { MeasurementPropertySheetComponent } from '../measurement/measurement-property-sheet/measurement-property-sheet.component';
import { PartitionPlanGeometryQueryService } from 'app/partition/partition-plan-geometry-query.service';
import { SecretComponent } from 'app/measurement/hidden-secret/secret/secret.component';
import { SwingFlexComponent } from './swing-flex/swing-flex.component';
import { SwingFlexImageComponent } from './swing-flex/swingFlexImage.component';
import { LeftRightSelectorComponent } from '../sidebar/left-right-selector/left-right-selector.component';
import { SubsectionHeaderComponent } from '../sidebar/subsection-header/subsection-header.component';
import { SwingFlexGableEditorComponent } from '../floor-plan-editing/swing-flex/swing-flex-gable-editor/swing-flex-gable-editor.component';
import { InteriorItemComponent } from '../floor-plan-editing/interior/interior-item/interior-item.component';

@NgModule({
  declarations: [
    BackingComponent,
    BackingImageVm,
    CorpusVm,
    D3DisplayVm,
    D3Vm,
    DoorImageVm,
    DoorVm,
    FloorPlanImageVm,
    FloorPlanPropertySheetComponent,
    FloorPlanVm,
    InteriorVm,
    Interior2dVm,
    ItemEditorVm,
    SwingImageVm,
    SwingVm,
    ValidationVm,
    FillingsVm,
    FillingsImageVm,
    FillingPropertySheetComponent,
    BarPropertySheetComponent,
    MeasurementPropertySheetComponent,
    SecretComponent,
    SwingFlexComponent,
    SwingFlexImageComponent,
    SwingFlexGableEditorComponent,
    InteriorItemComponent,
  ],
  exports: [
    BackingComponent,
    CorpusVm,
    D3DisplayVm,
    D3Vm,
    DoorVm,
    FloorPlanPropertySheetComponent,
    FloorPlanVm,
    InteriorVm,
    ItemEditorVm,
    SwingVm,
    ValidationVm,
    SwingFlexComponent,
  ],
  providers: [
    DragInfoService,
    SelectedItemsService,
    PartitionPlanCommandService,
    PartitionPlanDataService,
    PartitionPlanQueryService,
    PartitionPlanGeometryQueryService,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NgbModule,
    CornerNameBubbleModule,
    DarkSideBarModule,
    ErrorInfoModule,
    PartitionModule,
    PickableModule,
    ProductListModule,
    TopbarModule,
    I18NModule,
    LeftRightSelectorComponent,
    SubsectionHeaderComponent,
  ],
})
export class FloorPlanEditingModule {}
