import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { Interior as Client_Interior } from 'app/ts/clientDto/SubSectionInterior';
import { ProductHelper } from 'app/ts/util/ProductHelper';

import * as VariantNumbers from 'app/ts/VariantNumbers';
import { ChainSettingHelper } from 'app/ts/util/ChainSettingHelper';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { Injectable } from '@angular/core';
import { BackingType } from 'app/ts/Interface_Enums';
import { ConfigurationItemService } from '@Services/ConfigurationItemService';

@Injectable({ providedIn: 'root' })
export class InteriorLogic {
  public static readonly Name = 'interiorLogic';

  constructor(
    private readonly configurationItemService: ConfigurationItemService,
  ) {}

  // #region recalculation

  public recalculatePartOne(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Interior part one');

    let result: Client.RecalculationMessage[] = [];

    result.push(...this.removeUnknownProducts(section));

    // Ensure all general values are set and valid
    this.ensureValuesAreValid(section);

    if (!section.isSwing) {
      this.adjustYPositions(section);
    }

    this.adjustGableHeight(section);

    this.adjustYPositionsToDrillStop(section);

    this.moveModulesInside(section);

    this.recalculateModuleChildrenPositions(section);

    if (!section.isSwing && !section.isSwingFlex) {
      result.push(...this.adaptToWidth(section)); // Adjust to gable width changes
    }

    this.adjustVerticalDivider(section);

    if (section.isSwingFlex) {
      this.adjustFittingPanelXPositions(section);
      this.adjustSwingFlexFittingPanelYZPositions(section);
      this.adjustItemToGable(section);
      this.adjustYPositionsToDrillStop(section);
    } else {
      this.adjustItemDepthsAndZ(section);
      this.adjustFittingPanelYZPositions(section);
    }

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Interior part one');

    return result;
  }

  public recalculatePartTwo(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Interior part two');

    let result: Client.RecalculationMessage[] = [];

    this.adjustGableZPositions(section);

    if (!section.isSwingFlex) {
      // SwingFlex interior items are already adjusted in part one under adjustItemToGable
      this.adjustItemPositionsAndWidths(section);
    }

    for (let item of section.interior.items) {
      this.moveAllItemsInsideInteriorCube(item, 'X', true);
    }
    //for (let item of section.interior.items) {
    //    this.moveAllItemsInsideInteriorCube(item, "Y", true)
    //}
    for (let item of section.interior.items) {
      if (!ProductHelper.isStyling(item.Product)) continue;
      this.moveAllItemsInsideInteriorCube(item, 'Z', false);
    }

    this.setHasEmptyGaps(section);
    this.updatePreviousInteriorCube(section);

    result.push(...this.setCornerItemDim2(section));

    // Add joint variants
    this.setVerticalJointVariants(section);

    this.setFittingPanelConnections(section);

    this.adjustItemToFittingPanel(section);

    //Process corners (gables could have been moved possibly influencing dimensions of items in corners (eg. variants need recalc))
    this.processCorners(section);

    this.replaceAutoGeneratedItems(section);

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Interior part two');

    return result;
  }

  public cleanupBeforeCalculations(section: Client.CabinetSection): void {
    section.interior.items.forEach((item) => {
      this.clearSnapOppositeVariants(item);
    });
  }

  private setFittingPanelConnections(section: Client.CabinetSection): void {
    const fittingPanels = section.interior.items.filter(
      (item) => item.isFittingPanel,
    );

    this.fittingPanelConnections(section.interior.items, fittingPanels);
    this.fittingPanelConnections(section.swingFlex.items, fittingPanels);
  }

  private fittingPanelConnections(
    interiorItems: Client.ConfigurationItem[],
    fittingPanels: Client.ConfigurationItem[],
  ) {
    for (let item of interiorItems) {
      if (
        !item.Product ||
        (!ProductHelper.hasVariant(
          item.Product,
          VariantNumbers.AdaptionToFittingPanelAboveBelow,
        ) &&
          !ProductHelper.hasVariant(
            item.Product,
            VariantNumbers.GableAdaptionToFittingPanel,
          ))
      ) {
        continue;
      }

      const connecedFittingPanelsAbove = fittingPanels.filter(
        (fp) =>
          fp.X >= item.X && fp.rightX <= item.rightX && fp.Y === item.topY,
      );
      const connecedFittingPanelsBelow = fittingPanels.filter(
        (fp) =>
          fp.X >= item.X && fp.rightX <= item.rightX && fp.topY === item.Y,
      );

      let fittingPanelVertical = VariantNumbers.Values.FittingPanel_None;
      if (connecedFittingPanelsAbove.length > 0) {
        fittingPanelVertical = VariantNumbers.Values.FittingPanel_Above;
      } else if (connecedFittingPanelsBelow.length > 0) {
        fittingPanelVertical = VariantNumbers.Values.FittingPanel_Below;
      }
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.AdaptionToFittingPanelAboveBelow,
        fittingPanelVertical,
      );

      const connectedFittingPanels = connecedFittingPanelsBelow.concat(
        connecedFittingPanelsAbove,
      );
      const fittingDrilledRight = connectedFittingPanels.some(
        (fp) => fp.drilledRight,
      );
      const fittingDrilledLeft = connectedFittingPanels.some(
        (fp) => fp.drilledLeft,
      );
      const fittingPanelHorisontal =
        fittingDrilledRight && fittingDrilledLeft
          ? VariantNumbers.Values.Both
          : fittingDrilledRight
            ? VariantNumbers.Values.Left
            : fittingDrilledLeft
              ? VariantNumbers.Values.Right
              : VariantNumbers.Values.None;
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.GableAdaptionToFittingPanel,
        fittingPanelHorisontal,
      );
    }
  }

  private clearSnapOppositeVariants(item: Client.ConfigurationItem) {
    ConfigurationItemHelper.addDimensionVariantByNumber(
      item,
      VariantNumbers.DepthOppositeItemRight,
      0,
    );
    ConfigurationItemHelper.addDimensionVariantByNumber(
      item,
      VariantNumbers.DepthOppositeItemLeft,
      0,
    );
    ConfigurationItemHelper.addVariantOptionByNumbers(
      item,
      VariantNumbers.DepthOppositeItemCornerFitting,
      VariantNumbers.Values.No,
    );
  }

  private setSnapOppositeVariant(
    snappedAgainst: Client.ConfigurationItem,
    isCornerLeft: boolean,
    childItem?: Client.ConfigurationItem,
  ) {
    //The child items should not have the variant set
    if (!!childItem) {
      ConfigurationItemHelper.addDimensionVariantByNumber(
        childItem,
        VariantNumbers.DepthOppositeItemRight,
        0,
      );
      ConfigurationItemHelper.addDimensionVariantByNumber(
        childItem,
        VariantNumbers.DepthOppositeItemLeft,
        0,
      );
      ConfigurationItemHelper.addVariantOptionByNumbers(
        childItem,
        VariantNumbers.DepthOppositeItemCornerFitting,
        VariantNumbers.Values.No,
      );
    }
    //Depending on the corner "owning" the parent item the variant changes
    ConfigurationItemHelper.addDimensionVariantByNumber(
      snappedAgainst,
      VariantNumbers.DepthOppositeItemRight,
      isCornerLeft && !!childItem
        ? childItem.frontZ + childItem.reductionDepth
        : 0,
    );
    ConfigurationItemHelper.addDimensionVariantByNumber(
      snappedAgainst,
      VariantNumbers.DepthOppositeItemLeft,
      !isCornerLeft && !!childItem
        ? childItem.frontZ + childItem.reductionDepth
        : 0,
    );
    ConfigurationItemHelper.addVariantOptionByNumbers(
      snappedAgainst,
      VariantNumbers.DepthOppositeItemCornerFitting,
      isCornerLeft && !!childItem
        ? VariantNumbers.Values.Yes
        : VariantNumbers.Values.No,
    );
    ConfigurationItemHelper.addVariantOptionByNumbers(
      snappedAgainst,
      VariantNumbers.DepthOppositeItemCornerFitting,
      !isCornerLeft && !!childItem
        ? VariantNumbers.Values.Yes
        : VariantNumbers.Values.No,
    );
  }

  private removeUnknownProducts(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let removedItems = ConfigurationItemHelper.removeItemsWithoutProducts(
      section.interior.items,
    );

    if (removedItems.length > 0) {
      return [
        {
          cabinetSection: section,
          editorSection: Enums.EditorSection.Interior,
          severity: Enums.RecalculationMessageSeverity.Warning,
          key: 'interior_items_removed_discontinued',
          defaultValue:
            'Some interior items are discontinued and have been removed. Please ensure the solution is as expected.',
        },
      ];
    } else {
      return [];
    }
  }

  private ensureValuesAreValid(section: Client.CabinetSection) {
    // Material
    if (!this.isMaterialValid(section.interior)) {
      this.setDefaultMaterial(section.interior);
    }
  }

  private isMaterialValid(interior: Client_Interior): boolean {
    if (interior.material === null) {
      return false;
    } else {
      let id = interior.material.Id;
      return interior.pickableMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }

  private setDefaultMaterial(interior: Client_Interior) {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultInteriorMaterialNumber(
        interior.editorAssets,
      );

    if (defaultMaterialNumber) {
      material = Enumerable.from(interior.pickableMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === defaultMaterialNumber);
    }

    if (!material) {
      material = Enumerable.from(interior.pickableMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    if (material != null) {
      interior.setMaterial(material, false);
    }
  }

  private adjustYPositions(section: Client.CabinetSection) {
    // Adjust the Y position of all items, that are not a module parent or part of a module
    let adjustment = 0;
    let gables = section.interior.items.filter(
      (item) =>
        item.isGable &&
        item.moduleParent === null &&
        !item.IsLocked &&
        !item.isFittingPanel &&
        !item.isVerticalDivider,
    );

    for (let gable of gables) {
      let difference = section.interior.cube.Y - gable.Y;
      if (Math.abs(difference) > Math.abs(adjustment)) {
        adjustment = difference;
      }
    }

    const nonTemplateItems = section.interior.items.filter(
      (item) => item.moduleParent === null && !item.isTemplate,
    );

    //no gables to use for reference, try to look a gable in another section if one exists
    if (nonTemplateItems.length > 0 && gables.length == 0) {
      let leftNeighbor = section.leftNeighbor;
      if (!!leftNeighbor) {
        adjustment =
          leftNeighbor.interior.gables.length > 0
            ? section.interior.cube.Y - leftNeighbor.interior.gables[0].Y
            : 0;
      } else {
        let rightNeighbor = section.rightNeighbor;
        if (!!rightNeighbor) {
          adjustment =
            rightNeighbor.interior.gables.length > 0
              ? section.interior.cube.Y - rightNeighbor.interior.gables[0].Y
              : 0;
        }
      }
    }

    for (let item of nonTemplateItems) {
      item.Y += adjustment;
    }

    // Adjust module parent and child Y positions...
    for (let module of section.interior.items.filter(
      (item) => item.isTemplate,
    )) {
      let difference = section.interior.cube.Y - module.Y;
      if (Math.abs(difference) > Constants.sizeAndPositionTolerance) {
        module.Y += difference;
        module.moduleChildren.forEach((child) => (child.Y += difference));

        let itemsInModule = section.interior.items.filter(
          (item) =>
            item.moduleParent === null &&
            !item.isTemplate &&
            item.X > module.X &&
            item.rightX < module.rightX,
        );
        itemsInModule.forEach((child) => (child.Y += difference));
      }
    }
  }

  private adjustGableHeight(section: Client.CabinetSection) {
    for (let gable of section.interior.items.filter(
      (i) =>
        i.isGable &&
        !i.moduleParent &&
        !i.isFittingPanel &&
        !i.isVerticalDivider,
    )) {
      gable.trySetHeight(section.interior.cube.Height);
      if (gable.Height > section.interior.cube.Height) {
        //gable was probably locked. But cube height trumps item lock.
        gable.Height = section.interior.cube.Height;
      }
    }
    for (let gable of section.interior.items.filter(
      (i) =>
        i.isGable &&
        !!i.moduleParent &&
        !i.isFittingPanel &&
        !i.isVerticalDivider,
    )) {
      if (gable.moduleParent!.Height < gable.moduleParent!.minHeight) {
        gable.moduleParent!.Height = gable.moduleParent!.minHeight;
      }

      gable.trySetHeight(gable.moduleParent!.Height);
    }
  }

  private moveModulesInside(section: Client.CabinetSection) {
    for (let module of section.interior.items.filter(
      (item) => item.isTemplate,
    )) {
      // Too far right?
      let difference = module.rightX - section.interior.cubeRightX;
      if (difference > Constants.sizeAndPositionTolerance) {
        let itemsInModule = section.interior.items.filter(
          (item) =>
            item.moduleParent === null &&
            !item.isTemplate &&
            item.X > module.X &&
            item.rightX < module.rightX,
        );

        module.X -= difference;
        module.moduleChildren.forEach((child) => (child.X -= difference));

        itemsInModule.forEach((child) => (child.X -= difference));
      }

      // Too far left?
      difference = section.interior.cube.X - module.X;
      if (difference > Constants.sizeAndPositionTolerance) {
        let itemsInModule = section.interior.items.filter(
          (item) =>
            item.moduleParent === null &&
            !item.isTemplate &&
            item.X > module.X &&
            item.rightX < module.rightX,
        );

        module.X += difference;
        module.moduleChildren.forEach((child) => (child.X += difference));

        itemsInModule.forEach((child) => (child.X += difference));
      }
    }
  }

  private adjustGableZPositions(section: Client.CabinetSection) {
    let gables = section.interior.items.filter(
      (item) =>
        item.isGable && !(item.isFittingPanel || item.isVerticalDivider),
    );

    // Adjust the Z position of all gables, except snapfront gables
    for (let gable of gables.filter(
      (item) => item.isGable && !item.snapFront,
    )) {
      gable.Z = section.interior.cube.Z;
    }

    // Adjust Z position of snapfront gables (low support gables for use in modules)
    for (let gable of gables.filter((item) => item.isGable && item.snapFront)) {
      let closestModuleGable = Enumerable.from(
        gables.filter(
          (item) =>
            item.isGable && (item.drilling600Left || item.drilling600Right),
        ),
      )
        .orderBy((mg) => Math.abs(mg.X - gable.X))
        .firstOrDefault();
      if (!!closestModuleGable) {
        let newZ =
          closestModuleGable.frontZ - gable.depthReduction - gable.Depth;
        gable.Z = newZ;
      } else {
        gable.Z = section.interior.cube.Z;
      }
    }
  }

  private adjustItemPositionsAndWidths(section: Client.CabinetSection) {
    // Adjust items, so they fit in their "gaps"
    let gaps = this.getGapsBetweenGablesOrModules(section);
    for (let gap of gaps) {
      if (!gap.items.every((item) => item.snapsAgainstOpposite)) {
        gap.updateWidth(section.interior.cube.X, section.interior.cubeRightX);
      }
      gap.fitItems();
    }
  }

  private moveAllItemsInsideInteriorCube(
    item: Client.ConfigurationItem,
    dimension: 'X' | 'Y' | 'Z',
    resize: boolean,
  ) {
    let cube = item.cabinetSection.interior.cube;

    let size: 'Width' | 'Height' | 'Depth';
    let minSize: 'minWidth' | 'minHeight' | 'minDepth';
    if (dimension === 'X') {
      size = 'Width';
      minSize = 'minWidth';
    } else if (dimension === 'Y') {
      size = 'Height';
      minSize = 'minHeight';
    } else {
      size = 'Depth';
      minSize = 'minDepth';
    }

    if (item[dimension] < cube[dimension]) {
      item[dimension] = cube[dimension];
    }

    if (item[dimension] + item[size] > cube[dimension] + cube[size]) {
      //move item if it's off to the right
      item[dimension] = cube[dimension] + cube[size] - item[size];
    }

    let itemMaxSize = cube[dimension] + cube[size] - item[dimension];
    if (resize && itemMaxSize < item[size]) {
      //resize item if it's off to the left
      let newTargetSize = Math.max(item[minSize], itemMaxSize);
      if (item[size] !== newTargetSize) {
        item[size] = newTargetSize;
      }
    }
  }

  private adjustYPositionsToDrillStop(section: Client.CabinetSection) {
    for (let item of section.interior.items) {
      if (!(item.snapLeft || item.snapRight || item.snapLeftAndRight)) {
        continue;
      }

      if (item.isFittingPanel || item.isVerticalDivider) {
        continue;
      }

      let gableGap = InteriorLogic.getGableGap(section, item);

      if (
        !item.snapsAgainstOpposite &&
        gableGap &&
        gableGap.drillStops.length > 0
      ) {
        let currentY = item.Y;

        let bestDrillStop = ObjectHelper.best(
          gableGap.drillStops,
          (drillStop) => Math.abs(drillStop - item.Y - item.snapOffsetY),
        );

        if (
          bestDrillStop !== undefined &&
          bestDrillStop - item.snapOffsetY !== currentY
        ) {
          item.Y = bestDrillStop - item.snapOffsetY;
        }
      }
    }
  }

  private recalculateModuleChildrenPositions(section: Client.CabinetSection) {
    for (let item of section.interior.items) {
      if (item.Product && item.isTemplate) {
        item.Z = section.interior.cube.Z;

        for (
          let childI = 0;
          childI < item.Product.ModuleItems.length;
          childI++
        ) {
          let moduleItem = item.Product.ModuleItems[childI];
          let childItem = item.moduleChildren[childI];
          if (moduleItem && childItem) {
            childItem.X = moduleItem.X + item.X;
            childItem.Y = moduleItem.Y + item.Y;
            childItem.Z = moduleItem.Z + item.Z;
          }
        }

        let itemsEnumerable = Enumerable.from(section.interior.items);

        let itemsAddedToModule = itemsEnumerable
          .where(
            (i) =>
              i.X > item.X &&
              i.rightX < item.rightX &&
              item.moduleChildren.indexOf(i) < 0,
          )
          .toArray();

        for (let index = 0; index < itemsAddedToModule.length; index++) {
          let addedItem = itemsAddedToModule[index];

          // Find closest gable
          let gable = itemsEnumerable.lastOrDefault(
            (i) =>
              i.isGable &&
              Math.abs(i.rightX - addedItem.X) <
                Constants.sizeAndPositionTolerance,
          );
          if (gable === null) {
            gable = itemsEnumerable.firstOrDefault(
              (i) =>
                i.isGable &&
                Math.abs(i.X - addedItem.rightX) <
                  Constants.sizeAndPositionTolerance,
            );
          }

          if (!!gable) {
            addedItem.Z =
              gable.frontZ - addedItem.depthReduction - addedItem.Depth;
          }
        }
      }
    }
  }

  private moveUnsnappedItemsInside(section: Client.CabinetSection) {
    for (let item of section.interior.items.filter((i) => !i.isGable)) {
      if (
        item.X > section.interior.cube.X &&
        item.rightX < section.interior.cubeRightX
      ) {
        continue;
      }

      if (!section.interior.isItemSnappedToGable(item)) {
        item.X = Math.max(item.X, section.interior.cube.X);
        item.X =
          Math.min(item.rightX, section.interior.cubeRightX) - item.Width;
      }
    }
  }

  private adaptToWidth(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let messages: Client.RecalculationMessage[] = [];

    if (section.interior.items.length <= 0) {
      return messages;
    }

    this.moveUnsnappedItemsInside(section);

    let gaps = this.getGapsBetweenGablesOrModules(section);

    let spreadOutToFill = section.interior.mustAdaptToWidth;
    section.interior.mustAdaptToWidth = false;
    let spreadToFillLeft = false;
    let spreadToFillRight = false;

    if (!spreadOutToFill) {
      // If the interior width has changed, and there was not already an empty gap at the right side, we must adapt to the new width

      let widthChange =
        section.interior.cube.Width - section.interior.previousCubeWidth;
      if (widthChange > Constants.sizeAndPositionTolerance) {
        if (gaps.length === 0) {
          spreadToFillLeft = false;
          spreadToFillRight = false;
        } else {
          if (
            section.interior.cube.X < section.interior.previousCubeX &&
            !gaps[0].isRightGableLocked
          ) {
            spreadToFillLeft = true;
          }
          if (
            section.interior.cube.X + section.interior.cube.Width >
              section.interior.previousCubeX +
                section.interior.previousCubeWidth &&
            !gaps[gaps.length - 1].isRightGableLocked
          ) {
            spreadToFillRight = true;
          }
        }
      }
    }

    // Local function for adjusting gap width
    let adjustGapWidth = (gap: Client.InteriorGap, change: number) => {
      let newWidth = gap.width - change;

      let otherGaps = gaps
        .filter((g) => g.rightX > gap.rightX)
        .sort((a, b) => {
          return a.position - b.position;
        });
      if (gap.resize(newWidth)) {
        otherGaps.forEach((g) => {
          g.moveBy(-change);
          if (otherGaps.indexOf(g) === otherGaps.length - 1) {
            g.resize(g.width - change);
          }
        });
      }
    };

    // Ensure no items or gaps are outside the cabinet to the left
    if (gaps.some((gap) => gap.position < section.interior.cube.X)) {
      let minGapPos = Math.min(...gaps.map((g) => g.position));
      let moveAmount = section.interior.cube.X - minGapPos;

      gaps.forEach((g) => g.moveBy(moveAmount));
    }

    if (gaps.length > 1) {
      // Remove empty gap left, if it is not wider than the threshold
      let firstGap = gaps[0];
      if (
        firstGap &&
        firstGap.class === Enums.InteriorGapClass.Empty &&
        firstGap.width > Constants.sizeAndPositionTolerance &&
        firstGap.width <= Constants.minimumInteriorSideGapWidth
      ) {
        if (firstGap.rightGable && !firstGap.rightGable.IsLocked) {
          spreadToFillLeft = true;
          console.log('Interior - Removing narrow empty space left.');
        }
      }

      // Remove empty gap right, if it is not wider than the threshold (if not already set to do so)
      if (!spreadOutToFill) {
        let lastGap = gaps[gaps.length - 1];
        if (
          lastGap &&
          lastGap.class === Enums.InteriorGapClass.Empty &&
          lastGap.width > Constants.sizeAndPositionTolerance &&
          lastGap.width < Constants.minimumInteriorSideGapWidth
        ) {
          if (lastGap.leftGable && !lastGap.leftGable.IsLocked) {
            spreadToFillRight = true;
            console.log('Interior - Removing narrow empty space right.');
          }
        }
      }
    }

    if (gaps.some((gap) => gap.rightX > section.interior.cubeRightX)) {
      // Too narrow (items outside the cabinet to the right):
      // - Make room for the items by adjusting empty, flexible or semiflexible areas

      gaps.reverse(); // Start from the right...

      let neededSpace =
        Math.max(...gaps.map((g) => g.rightX), 0) - section.interior.cubeRightX;

      // First try empty space
      let emptyGaps = gaps.filter(
        (g) => g.class === Enums.InteriorGapClass.Empty && g.width > 0,
      );
      if (emptyGaps.length > 0) {
        let totalEmptySpace = emptyGaps
          .map((g) => g.width)
          .reduce((a, b) => a + b);

        while (neededSpace > 0 && totalEmptySpace > 0 && emptyGaps.length > 0) {
          let currentGap = emptyGaps.splice(0, 1)[0];
          let change = Math.min(currentGap.width, neededSpace);
          adjustGapWidth(currentGap, change);
          neededSpace -= change;
        }
      }

      if (neededSpace > 0) {
        // If that was not enough, try flexible gaps
        let flexGaps = gaps.filter(
          (g) => g.class === Enums.InteriorGapClass.Flex && g.width > 0,
        );
        if (flexGaps.length > 0) {
          let totalFlexSpace = flexGaps
            .map((g) => g.maxContraction)
            .reduce((a, b) => a + b);
          // Adjusting by factor may not be possible, because of gap min width constraints...
          // So we adjust one gap at a time

          while (neededSpace > 0 && totalFlexSpace > 0 && flexGaps.length > 0) {
            let currentGap = flexGaps.splice(0, 1)[0];
            let change = Math.min(currentGap.maxContraction, neededSpace);
            adjustGapWidth(currentGap, change);
            neededSpace -= change;
          }
        }
      }

      if (neededSpace > 0) {
        // If that was still not enough, try semiflexible gaps
        let semiFlexGaps = gaps.filter(
          (g) => g.class === Enums.InteriorGapClass.SemiFlex && g.width > 0,
        );
        if (semiFlexGaps.length > 0) {
          let totalSemiFlexSpace = semiFlexGaps
            .map((g) => g.maxContraction)
            .reduce((a, b) => a + b);

          while (
            neededSpace > 0 &&
            totalSemiFlexSpace > 0 &&
            semiFlexGaps.length > 0
          ) {
            let currentGap = semiFlexGaps.splice(0, 1)[0];
            let change = Math.min(currentGap.maxContraction, neededSpace);
            adjustGapWidth(currentGap, change);
            neededSpace -= change;
          }
        }
      }

      if (neededSpace > 0) {
        // If we still need more space when we get here, we are out of luck.
        // There is simply nowhere to take it from.
        // Later in the recalculation, items that are still outside the cabinet are pushed inside.

        let message: Client.RecalculationMessage = {
          cabinetSection: section,
          editorSection: Enums.EditorSection.Interior,
          key: 'AdjustInteriorToLessAvailableSpace',
          defaultValue:
            'It was not possible to adjust interior to the available space.',
          severity: Enums.RecalculationMessageSeverity.Info,
        };
        messages.push(message);
      }
    } else if (spreadOutToFill) {
      console.log('Interior space to wide.');

      // Handle excess space to the left
      if (!section.interior.hasEmptyGapLeft) {
        let firstGap = gaps[0];

        if (firstGap.width > 0) {
          if (firstGap.class === Enums.InteriorGapClass.Empty) {
            // There is an empty area  on the left.
            // It must be resized to zero width, and all other gaps moved accordingly.
            adjustGapWidth(firstGap, firstGap.width);
          } else if (!firstGap.allItemsFit) {
            let gapIndex = this.findGapIndex(
              gaps,
              firstGap.class,
              false,
              Constants.sizeAndPositionTolerance,
            );

            // If we found a suitable gap, do some adjusting and moving
            if (gapIndex > 0) {
              let newWidth = firstGap.maxItemWidth;
              let change = newWidth - firstGap.width;
              let otherGaps = gaps
                .filter(
                  (g) =>
                    g.rightX > firstGap.rightX && gaps.indexOf(g) < gapIndex,
                )
                .sort((a, b) => {
                  return a.position - b.position;
                });

              if (firstGap.resize(newWidth)) {
                otherGaps.forEach((g) => {
                  g.moveBy(change);
                  if (otherGaps.indexOf(g) === otherGaps.length - 1) {
                    g.fitItems();
                  }
                });
              }
            } else {
              firstGap.fitItems();
            }
          }
        }
      }

      // Handle excess space to the right
      let lastGap = gaps[gaps.length - 1];
      if (
        lastGap &&
        lastGap.class === Enums.InteriorGapClass.Empty &&
        lastGap.width > 0 &&
        !section.rightNeighbor
      ) {
        // Too wide (space to the right of the last gable):
        // - Remove the empty area by adjusting flexible or semiflexible areas

        let excessSpaceRight = lastGap.width; //section.interior.cubeRightX - Math.max(...gaps.map(g => g.rightX), 0);

        let adjustGapByFactor = (gap: Client.InteriorGap, factor: number) => {
          let newWidth = gap.width * factor;
          let change = newWidth - gap.width;

          adjustGapWidth(gap, -change);
          excessSpaceRight -= change;
        };

        // First try empty space
        if (!section.interior.hasEmptyGapRight) {
          let emptyGaps = gaps.filter(
            (g) =>
              g.class === Enums.InteriorGapClass.Empty &&
              g.width > 0 &&
              g !== lastGap,
          );
          if (emptyGaps.length > 0) {
            let totalEmptySpace = emptyGaps
              .map((g) => g.width)
              .reduce((a, b) => a + b);
            //let preferredEmptySpace = totalEmptySpace + excessSpaceRight;
            let factor = (totalEmptySpace + excessSpaceRight) / totalEmptySpace;

            console.log('Adjusting empty gaps by factor ' + factor);

            emptyGaps.forEach((g) => adjustGapByFactor(g, factor));
          }
        }

        // From here on, adjusting by factor may not be correct, because of gap width constraints.
        // This may actually result in validation errors, if gaps are adjusted beyond their limits.
        // To avoid this, we could adjust one gap at time, but the old system did it like this, and now so does this. (For now, anyway...)

        if (excessSpaceRight >= 1) {
          // Try adjusting flexible gaps

          let flexGaps = gaps.filter(
            (g) =>
              g.class === Enums.InteriorGapClass.Flex &&
              g.width > 0 &&
              g.maxExpansion > 0,
          );
          if (flexGaps.length > 0) {
            let totalFlexSpace = flexGaps
              .map((g) => g.width)
              .reduce((a, b) => a + b);
            let factor = (totalFlexSpace + excessSpaceRight) / totalFlexSpace;

            console.log('Adjusting flexible gaps by factor ' + factor);

            flexGaps.forEach((g) => adjustGapByFactor(g, factor));
          }
        }

        if (excessSpaceRight >= 1) {
          // Try adjusting semiflexible gaps

          let semiFlexGaps = gaps.filter(
            (g) =>
              g.class === Enums.InteriorGapClass.SemiFlex &&
              g.width > 0 &&
              g.maxExpansion > 0,
          );
          if (semiFlexGaps.length > 0) {
            let totalSemiFlexSpace = semiFlexGaps
              .map((g) => g.maxContraction)
              .reduce((a, b) => a + b);
            let factor =
              (totalSemiFlexSpace + excessSpaceRight) / totalSemiFlexSpace;

            console.log('Adjusting semi flexible gaps by factor ' + factor);

            semiFlexGaps.forEach((g) => adjustGapByFactor(g, factor));
          }
        }

        if (excessSpaceRight >= 1) {
          // If we still have some excess space when we get here, we are out of luck.
          // There is simply nowhere to put it.
          // All we can do, is let the user know.

          let message: Client.RecalculationMessage = {
            cabinetSection: section,
            editorSection: Enums.EditorSection.Interior,
            key: 'AdjustInteriorToFillAllAvailableSpace',
            defaultValue:
              'It was not possible to adjust interior to fill all available space.',
            severity: Enums.RecalculationMessageSeverity.Info,
          };
          messages.push(message);
        }
      }
    } else {
      if (spreadToFillLeft) {
        let firstGap = gaps[0];
        let gapIndex = this.findGapIndex(
          gaps,
          firstGap.class > 0 ? firstGap.class : Enums.InteriorGapClass.Fixed,
          false,
          section.interior.hasEmptyGapRight
            ? 0
            : Constants.sizeAndPositionTolerance,
        );

        // If we found a suitable gap, do some adjusting and moving
        if (gapIndex > 0 && !section.leftNeighbor) {
          let newWidth = firstGap.maxItemWidth;
          let change = newWidth - firstGap.actualWidth;

          // Get all gaps to the right of the first gap, that are not vertical dividers
          let otherGaps = gaps
            .filter(
              (g) =>
                g.rightX > firstGap.rightX &&
                gaps.indexOf(g) < gapIndex &&
                !(
                  g.rightGable?.isVerticalDivider ||
                  g.leftGable?.isVerticalDivider
                ),
            )
            .sort((a, b) => {
              return a.position - b.position;
            });

          if (firstGap.resize(newWidth)) {
            otherGaps.forEach((g) => {
              g.moveBy(change);
              if (otherGaps.indexOf(g) === otherGaps.length - 1) {
                g.updateWidth(
                  section.interior.cube.X,
                  section.interior.cubeRightX,
                );
                g.fitItems();
              }
            });

            let nextGap = gaps[gapIndex];
            nextGap.updateWidth(
              section.interior.cube.X,
              section.interior.cubeRightX,
            );
          }
        }
      }
      if (spreadToFillRight) {
        let lastGap = gaps[gaps.length - 1];
        let gapIndex = this.findGapIndex(
          gaps,
          lastGap.class > 0 ? lastGap.class : Enums.InteriorGapClass.Fixed,
          true,
          section.interior.hasEmptyGapLeft
            ? 0
            : Constants.sizeAndPositionTolerance,
        );

        // If we found a suitable gap, do some adjusting and moving
        if (gapIndex > 0) {
          let newWidth = lastGap.maxItemWidth;
          let change = lastGap.actualWidth - newWidth;

          // Get all gaps to the left of the last gap, that are not vertical dividers
          let otherGaps = gaps
            .filter(
              (g) =>
                g.position < lastGap.position &&
                gaps.indexOf(g) >= gapIndex &&
                !(
                  g.rightGable?.isVerticalDivider ||
                  g.leftGable?.isVerticalDivider
                ),
            )
            .sort((a, b) => {
              return a.position - b.position;
            });

          for (let i = 0; i < otherGaps.length; i++) {
            let otherGap = otherGaps[i];
            if (i === 0) {
              lastGap.moveBy(change);
              otherGap.resize(otherGap.actualWidth + change);
            } else {
              otherGap.moveBy(change);
            }
          }

          lastGap.resize(newWidth);
        }
      }
    }

    return messages;
  }

  private findGapIndex(
    gaps: Client.InteriorGap[],
    excludeClass: Enums.InteriorGapClass,
    fromRight: boolean,
    minWidth: number,
  ): number {
    let gapIndex = -1;

    // Find the first empty gap (without passing a gap with locked right gable)
    let emptyGap = this.findGapOfClass(
      gaps,
      Enums.InteriorGapClass.Empty,
      fromRight,
      minWidth,
      true,
    );
    if (emptyGap) {
      gapIndex = gaps.indexOf(emptyGap);
    }

    // If we did not find an empty gap, find the first flex gap (without passing a gap with locked right gable)
    if (gapIndex <= 0 && excludeClass > Enums.InteriorGapClass.Flex) {
      let flexGap = this.findGapOfClass(
        gaps,
        Enums.InteriorGapClass.Flex,
        fromRight,
        minWidth,
        true,
      );
      if (flexGap) {
        gapIndex = gaps.indexOf(flexGap);
      }
    }

    // If we did not find a flex gap, find the first semiFlex gap (without passing a gap with locked right gable)
    if (gapIndex <= 0 && excludeClass > Enums.InteriorGapClass.SemiFlex) {
      let semiFlexGap = this.findGapOfClass(
        gaps,
        Enums.InteriorGapClass.SemiFlex,
        fromRight,
        minWidth,
        true,
      );
      if (semiFlexGap) {
        gapIndex = gaps.indexOf(semiFlexGap);
      }
    }

    return gapIndex;
  }

  private findGapOfClass(
    gaps: Client.InteriorGap[],
    gapClass: Enums.InteriorGapClass,
    reverseOrder: boolean,
    minWidth: number,
    ignoreFirst: boolean,
  ): Client.InteriorGap | undefined {
    let orderedGaps = gaps.slice().sort((a, b) => {
      if (!reverseOrder) return a.position - b.position;
      else return b.position - a.position;
    });

    for (var i = ignoreFirst ? 1 : 0; i < orderedGaps.length; i++) {
      let gap = orderedGaps[i];

      if (reverseOrder && gap.isRightGableLocked) {
        return undefined;
      }

      if (gap.class === gapClass && gap.width >= minWidth) {
        return gap;
      }
    }

    return undefined;
  }

  private processCorners(
    section: Client.CabinetSection,
    gaps?: Client.InteriorGap[],
  ): Client.ConfigurationItem[] {
    //This area deals with items that can snap against items of the same type (snap opposite type) in a corner. Left corner first.
    //Gaps is optional, if undefined it wall just skip adding gaps to the supplied list
    let specialCornerItems: Client.ConfigurationItem[] = [];
    let gablesAndModules = this.getGablesAndModules(section);

    let otherItems: Client.ConfigurationItem[] = section.interior.items.filter(
      (item) => !item.isGable && !item.isTemplate && !item.isDummy,
    );

    otherItems.push(
      ...section.interior.items.filter((item) => item.isFittingPanel),
    );

    let leftNeighbor = section.leftNeighbor;
    if (!!leftNeighbor) {
      let gable = gablesAndModules[0];
      if (!!gable) {
        let lastEnd =
          leftNeighbor.interior.cube.X + leftNeighbor.interior.cube.Width; //want the right most point, so we only find items snapped against the "wall"
        let neighborGaps = InteriorLogic.getGableGaps(leftNeighbor);
        let neighborGap = neighborGaps.filter((gap) => !gap.rightGable)[0];
        let cornerItems = leftNeighbor.interior.items.filter(
          (item) =>
            item.snapsAgainstOpposite &&
            item.X >= neighborGap.X &&
            item.rightX === lastEnd,
        );

        for (let corner of cornerItems) {
          let itemsToExamine = otherItems.filter(
            (item) =>
              corner.bottomY === item.bottomY &&
              corner.snapsAgainstOpposite &&
              item.snapsAgainstOpposite &&
              corner.ProductId === item.ProductId &&
              item.X <= gable.X,
          );

          if (itemsToExamine.length > 0) {
            //only one item should match
            let snappedItem = itemsToExamine[0];
            this.setSnapOppositeVariant(corner, true, snappedItem);
          } else {
            this.setSnapOppositeVariant(corner, true);
          }

          for (let ite of itemsToExamine) specialCornerItems.push(ite);
          if (!!gaps) {
            let gap: Client.InteriorGap = new Client.InteriorGap(
              0,
              null,
              gable,
              itemsToExamine,
              corner.frontZ,
              gable.X - corner.frontZ,
            );
            gaps.push(gap);
          }
        }
      }
    }

    //This area deals with items that can snap against items of the same type in a corner. Now right corner.
    let rightNeighbor = section.rightNeighbor;
    if (!!rightNeighbor) {
      let gable = gablesAndModules[gablesAndModules.length - 1];
      if (!!gable) {
        let firstEnd = rightNeighbor.interior.cube.X; //want the right most point, so we only find items snapped against the "wall"
        let neighborGaps = InteriorLogic.getGableGaps(rightNeighbor);
        let neighborGap = neighborGaps.filter((gap) => !gap.leftGable)[0];
        let cornerItems = rightNeighbor.interior.items.filter(
          (item) =>
            item.snapsAgainstOpposite &&
            item.X <= neighborGap.X &&
            item.leftX === firstEnd,
        );

        for (let corner of cornerItems) {
          let itemsToExamine = otherItems.filter(
            (item) =>
              corner.bottomY === item.bottomY &&
              corner.snapsAgainstOpposite &&
              item.snapsAgainstOpposite &&
              corner.ProductId === item.ProductId &&
              item.centerX >= gable.rightX,
          );

          if (itemsToExamine.length > 0) {
            //only one item should match
            let snappedItem = itemsToExamine[0];
            this.setSnapOppositeVariant(corner, false, snappedItem);
          } else {
            this.setSnapOppositeVariant(corner, false);
          }

          for (let ite of itemsToExamine) specialCornerItems.push(ite);
          if (!!gaps) {
            let gap: Client.InteriorGap = new Client.InteriorGap(
              gablesAndModules.length - 2,
              gable,
              null,
              itemsToExamine,
              gable.rightX,
              section.interior.cube.Width - corner.frontZ - gable.rightX,
            );
            gaps.push(gap);
          }
        }
      }
    }
    return specialCornerItems;
  }

  private getGablesAndModules(
    section: Client.CabinetSection,
  ): Client.ConfigurationItem[] {
    let gablesAndModules: Client.ConfigurationItem[] = [];
    gablesAndModules.push(
      ...section.interior.items.filter(
        (item) =>
          item.isGable &&
          item.moduleParent === null &&
          !item.isFittingPanel &&
          !item.isVerticalDivider,
      ),
    );
    gablesAndModules.push(
      ...section.interior.items.filter((item) => item.isTemplate),
    );
    if (section.isSwing) {
      gablesAndModules.push(
        ...section.swing.items.filter((item) => item.isGable),
      );
    }

    gablesAndModules.sort((a, b) => {
      return a.X - b.X;
    });
    return gablesAndModules;
  }

  private getGapsBetweenGablesOrModules(
    section: Client.CabinetSection,
  ): Client.InteriorGap[] {
    let gaps: Client.InteriorGap[] = [];

    let gablesAndModules = this.getGablesAndModules(section);

    let otherItems: Client.ConfigurationItem[] = section.interior.items.filter(
      (item) => !item.isGable && !item.isTemplate && !item.isDummy,
    );

    otherItems.push(
      ...section.interior.items.filter((item) => item.isFittingPanel),
    );

    let specialCornerItems = this.processCorners(section, gaps);
    otherItems = otherItems.filter(
      (item) => !specialCornerItems?.includes(item),
    );

    let startX = section.interior.cube.X;

    let gapIndex: number;
    for (gapIndex = 0; gapIndex < gablesAndModules.length; gapIndex++) {
      let leftGable = gapIndex > 0 ? gablesAndModules[gapIndex - 1] : null;
      let rightGable = gablesAndModules[gapIndex];
      let endX = rightGable.X;
      let gapItems = otherItems.filter(
        (item) =>
          (leftGable === null || item.Y < leftGable.topY) &&
          (rightGable === null || item.Y < rightGable.topY) &&
          item.centerX >= startX &&
          item.centerX <= endX,
      );
      let gap: Client.InteriorGap = new Client.InteriorGap(
        gapIndex,
        leftGable,
        rightGable,
        gapItems,
        startX,
        endX - startX,
      );

      let previousFarRightGable: Client.ConfigurationItem | undefined =
        undefined;

      for (
        let gableIndex = gapIndex;
        gableIndex <= gablesAndModules.length;
        gableIndex++
      ) {
        let farRightGable: Client.ConfigurationItem | undefined =
          gablesAndModules[gableIndex];

        if (
          farRightGable &&
          previousFarRightGable &&
          previousFarRightGable.topY >= farRightGable.topY
        )
          break;

        for (let item of otherItems) {
          if (item.drilling600Left || item.drilling600Right) continue;
          if (farRightGable && item.topY > farRightGable.topY) continue;
          if (previousFarRightGable && item.Y < previousFarRightGable.topY)
            continue;
          if (leftGable && item.Y > leftGable.topY) continue;
          if (farRightGable && item.X > farRightGable.X) continue;
          if (leftGable && item.rightX < leftGable.rightX) continue;
          if (item.snapUnder) continue;

          gap.multiGapItems.push({
            item: item,
            gable: gablesAndModules.filter(
              (g) => g.X > item.centerX && g.topY > item.Y,
            )[0],
          });
        }
        previousFarRightGable = farRightGable;
      }
      gaps.push(gap);

      startX = rightGable.rightX;
    }

    // Gap to the right of the last gable
    {
      let endX = Math.max(
        section.interior.cube.X + section.interior.cube.Width,
        section.interior.maxItemRightX,
      );
      let leftGable =
        gablesAndModules.length > 0
          ? gablesAndModules[gablesAndModules.length - 1]
          : null;

      let gap: Client.InteriorGap = new Client.InteriorGap(
        gapIndex,
        leftGable,
        null,
        otherItems.filter(
          (item) =>
            specialCornerItems?.indexOf(item) < 0 &&
            item.centerX >= startX &&
            item.centerX <= endX &&
            (!leftGable || item.Y < leftGable.topY),
        ),
        startX, // position
        endX - startX, // width
      );
      gaps.push(gap);
    }

    return gaps;
  }

  private setHasEmptyGaps(section: Client.CabinetSection) {
    section.interior.hasEmptyGapLeft = !section.interior.items.some(
      (ii) =>
        ii.X < section.interior.cube.X + Constants.sizeAndPositionTolerance,
    );
    section.interior.hasEmptyGapRight = !section.interior.items.some(
      (ii) =>
        ii.rightX >
        section.interior.cubeRightX - Constants.sizeAndPositionTolerance,
    );
  }

  private updatePreviousInteriorCube(section: Client.CabinetSection) {
    section.interior.previousCubeWidth = section.interior.cube.Width;
    section.interior.previousCubeX = section.interior.cube.X;
  }

  // #endregion recalculation

  /**
   * Sets vertical joint variants on items, where the actual height is bigger than their max height
   * @param section
   */
  private setVerticalJointVariants(section: Client.CabinetSection) {
    for (let item of section.interior.items) {
      let maxHeight = item.maxHeight;

      // Reset any joint positions
      ConfigurationItemHelper.removeItemVariantByVariantNumber(
        item,
        VariantNumbers.JointPosition,
      );

      if (item.ActualHeight > maxHeight && maxHeight > 0) {
        ConfigurationItemHelper.addVariantOptionByNumbers(
          item,
          VariantNumbers.Joint,
          VariantNumbers.Values.Yes,
        );

        let numberOfJoints = Math.floor(item.ActualHeight / maxHeight);
        let rest = item.ActualHeight % maxHeight;
        let minHeight = item.minHeight;

        for (var i = 0; i < numberOfJoints; i++) {
          let pos = (i + 1) * maxHeight;
          if (i == numberOfJoints - 1) {
            //last joint
            // Make sure the last part is not too small
            if (rest < minHeight) pos -= minHeight - rest;
          }

          ConfigurationItemHelper.addDimensionVariantOptionByNumber(
            item,
            VariantNumbers.JointPosition,
            i,
            pos,
          );
        }
      } else {
        // Set joint variant to "no"
        ConfigurationItemHelper.addVariantOptionByNumbers(
          item,
          VariantNumbers.Joint,
          VariantNumbers.Values.No,
        );
      }
    }
  }

  private setCornerItemDim2(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];
    if (!section.rightNeighbor) return result;

    let neighborGaps = InteriorLogic.getGableGaps(section.rightNeighbor, {
      ignorePulloutSeverity: true,
    }).filter((gap) => !gap.leftGable);

    //If a corpus item has been added in the bottom of the cabinet, this will not have been reflected in the next section yet
    //So we get the difference to use when looking for the correct gap
    let sectionDiffInY =
      section.interior.cube.Y - section.rightNeighbor.interior.cube.Y;

    for (let item of section.interior.items) {
      if (!item.isCorner2WayFlex && !item.isCornerDiagonalCut) {
        continue;
      }
      if (item.IsLocked) continue;

      let gap = neighborGaps.filter(
        (g) => g.Y + g.Height + sectionDiffInY >= item.Y,
      )[0];
      if (!gap) {
        continue;
      }
      let width2 = gap.Width;
      item.width2 = ObjectHelper.clamp(item.minWidth, width2, item.maxWidth);
      let depth2 = gap.rightGable
        ? gap.rightGable.Depth
        : section.rightNeighbor.InteriorDepth;

      if (item.ProductData) {
        depth2 -= item.ProductData.ReductionDepth;
      }

      item.depth2 = ObjectHelper.clamp(item.minDepth, depth2, item.maxDepth);
    }
    return result;
  }

  public static getCollidingCollisionAreas(
    item1: Client.ConfigurationItem,
    isMirrored: boolean,
    otherItems: Client.ItemCube[],
    item1AlternativePosition?: Interface_DTO_Draw.Cube,
  ): Client.Collision[] {
    let position = item1AlternativePosition || {
      X: item1.X,
      Y: item1.Y,
      Z: item1.Z,
      Width: item1.Width,
      Height: item1.Height,
      Depth: item1.Depth,
    };

    function shouldIgnore(i: Client.ConfigurationItem): boolean {
      if (i.isDummy) return true;
      if (i.isTemplate) return true;
      if (i.isGrip && i.Product && ProductHelper.edgeMounted(i.Product))
        return true;
      return false;
    }

    if (shouldIgnore(item1)) return [];

    let productLineProperties =
      item1.cabinetSection.swingFlex.productLineProperties;

    let collisionAreas = Enumerable.from(
      this.getCollisionAreas(position, item1, isMirrored),
    );

    let collisions = otherItems.map((otherItemCube) => {
      if (shouldIgnore(otherItemCube.item)) return null;
      if (otherItemCube.item === item1) return null;
      if (item1.isCoatHanger && otherItemCube.item.snapCoatHanger) {
        return null;
      }
      if (otherItemCube.item.isCoatHanger && item1.snapCoatHanger) {
        return null;
      }

      const thresholdY = item1.cabinetSection.isSwingFlex
        ? item1.cabinetSection.swingFlex.getAllowedOverlapY(
            item1,
            otherItemCube.item,
          )
        : 0;

      let collisionArea = collisionAreas.firstOrDefault((ca) =>
        VectorHelper.overlapsMoreThanThreshold(
          ca,
          otherItemCube,
          0,
          thresholdY,
          0,
        ),
      );
      if (!collisionArea) return null;

      let result: Client.Collision = {
        ...collisionArea,
        offender: otherItemCube.item,
      };
      return result;
    });

    let boundingBoxCollisionArea = collisionAreas.firstOrDefault((ca) =>
      item1.cabinetSection.interior.boundingBoxes.some((bound) =>
        VectorHelper.overlaps(ca, bound),
      ),
    );

    let boundingBoxCollision: Client.Collision | null;

    if (boundingBoxCollisionArea) {
      boundingBoxCollision = {
        ...boundingBoxCollisionArea,
        offender: null,
      };
    } else {
      boundingBoxCollision = null;
    }

    return collisions
      .concat(boundingBoxCollision)
      .filter(<(c: any) => c is Client.Collision>((c) => !!c)) //remove nulls
      .filter((c, index, arr) => arr.indexOf(c) === index); //remove duplicates
  }

  public static getCollisionAreas(
    baseCube: Interface_DTO_Draw.Cube,
    owner: Client.ConfigurationItem,
    mirrored: boolean,
  ): Client.CollisionArea[] {
    let collisionAreas: Client.CollisionArea[] = [];
    let product = owner.Product;
    if (!product) return collisionAreas;

    collisionAreas.push({
      ...baseCube,
      owner: owner,
      severity: Enums.CollisionSeverity.Overlap,
      space: null,
    });
    for (let recommended of [false, true]) {
      let severity = recommended
        ? Enums.CollisionSeverity.Recommended
        : Enums.CollisionSeverity.Required;

      let topSpace = ProductHelper.getCollisionSpace(
        product,
        recommended,
        Enums.CollisionSpacePosition.Top,
      );
      if (topSpace > 0) {
        collisionAreas.push({
          X: baseCube.X,
          Y: baseCube.Y + baseCube.Height,
          Z: baseCube.Z,
          Width: baseCube.Width,
          Height: topSpace,
          Depth: baseCube.Depth,
          severity: severity,
          space: Enums.CollisionSpacePosition.Top,
          owner: owner,
        });
      }
      let bottomSpace = ProductHelper.getCollisionSpace(
        product,
        recommended,
        Enums.CollisionSpacePosition.Bottom,
      );
      if (bottomSpace > 0) {
        collisionAreas.push({
          X: baseCube.X,
          Y: baseCube.Y - bottomSpace,
          Z: baseCube.Z,
          Width: baseCube.Width,
          Height: bottomSpace,
          Depth: baseCube.Depth,
          severity: severity,
          space: Enums.CollisionSpacePosition.Bottom,
          owner: owner,
        });
      }
      let sideSpace = ProductHelper.getCollisionSpace(
        product,
        recommended,
        Enums.CollisionSpacePosition.Side,
      );
      if (sideSpace > 0) {
        if (mirrored) {
          //get collision space for left side
          collisionAreas.push({
            X: baseCube.X - sideSpace,
            Y: baseCube.Y,
            Z: baseCube.Z,
            Depth: baseCube.Depth,
            Width: sideSpace,
            Height: baseCube.Height,
            severity: severity,
            space: Enums.CollisionSpacePosition.Side,
            owner: owner,
          });
        } else {
          //get collision space for right side
          collisionAreas.push({
            X: baseCube.X + baseCube.Width,
            Y: baseCube.Y,
            Z: baseCube.Z,
            Depth: baseCube.Depth,
            Width: sideSpace,
            Height: baseCube.Height,
            severity: severity,
            space: Enums.CollisionSpacePosition.Side,
            owner: owner,
          });
        }
      }
    }
    return collisionAreas;
  }

  public static getGableGap(
    section: Client.CabinetSection,
    item: Client.ConfigurationItem,
  ): Client.GableGap {
    let gaps = this.getGableGaps(section, {
      excludeItems: [item],
      ignorePulloutSeverity: true,
    });
    let result = gaps.find(
      (g) =>
        g.X === item.X &&
        g.Width === item.Width &&
        g.Y <= item.Y &&
        g.Height + g.Y >= item.topY,
    );
    if (result) {
      return result;
    }

    let gables = Enumerable.from(section.interior.gables)
      .concat(section.swing.items.filter((i) => i.isGable))
      .concat(section.swingFlex.items.filter((i) => i.isGable))
      .where((g) => g.isGable && !g.isFittingPanel && !g.isVerticalDivider)
      .orderBy((g) => g.X);
    let leftGable =
      gables.lastOrDefault((g) => g.centerX < item.X && g.topY >= item.topY) ??
      null;
    let rightGable =
      gables.firstOrDefault(
        (g) => g.centerX > item.rightX && g.topY >= item.topY,
      ) ?? null;

    let gapHeight = section.interior.cube.Height;
    let gapBottom = 0;
    if (gables.any()) {
      gapBottom = Math.min(...gables.select((g) => g.Y).toArray());
    }

    let gapLeftX = leftGable ? leftGable.rightX : section.interior.cube.X;
    let gapRightX = rightGable
      ? rightGable.X
      : section.interior.cube.X + section.interior.cube.Width;

    let rect: Interface_DTO_Draw.Rectangle = {
      X: gapLeftX,
      Y: section.interior.cube.Y,
      Width: gapRightX - gapLeftX,
      Height: gapHeight,
    };

    let gap: Client.GableGap = {
      ...rect,
      pulloutSeverity: this.getMaxSeverity(
        section.doors.pulloutWarningAreas,
        rect.X,
        rect.X + rect.Width,
      ),
      leftGable: leftGable,
      rightGable: rightGable,
      drillStops: this.getDrillStops(
        section.cabinet.productLine,
        gapBottom,
        gapBottom + gapHeight,
      ),
      startsAtBottom: true,
    };

    return gap;
  }

  public static getFreeGableGaps(
    items: Client.ConfigurationItem[],
    excludeItems: Client.ConfigurationItem[],
    productLine: Interface_DTO.ProductLine,
  ): Client.GableGap[] {
    let result: Client.GableGap[] = [];
    let includedItems = items.filter((i) => excludeItems.indexOf(i) < 0);
    let gables = includedItems
      .filter((i) => i.isGable)
      .sort((i1, i2) => i1.X - i2.X);

    if (gables.length < 1) {
      return [];
    }

    let lastLeftGables: {
      X: number;
      TopY: number;
      leftGable: Client.ConfigurationItem | null;
    }[] = [
      {
        X: gables[0].rightX,
        TopY: gables[0].topY,
        leftGable: gables[0],
      },
    ];

    for (let rightGable of gables.slice(1)) {
      let lastY = Math.min(rightGable.Y, lastLeftGables[0].leftGable!.Y);
      let startsAtBottom = true;
      for (let gableInfo of lastLeftGables) {
        let pos = {
          X: gableInfo.X,
          Y: startsAtBottom
            ? Math.min(rightGable.Y, gableInfo.leftGable!.Y)
            : lastY,
        };
        let size = {
          X: rightGable.X - gableInfo.X,
          Y: Math.min(gableInfo.TopY, rightGable.Y + rightGable.Height),
        };

        result.push({
          ...pos,
          Width: size.X,
          Height: size.Y,
          pulloutSeverity: Enums.PulloutWarningSeverity.None,
          leftGable: gableInfo.leftGable,
          rightGable: rightGable,
          drillStops: this.getDrillStops(
            productLine,
            Math.min(...gables.map((g) => g.Y)),
            size.Y,
            lastY,
          ),
          startsAtBottom: startsAtBottom,
        });
        lastY = gableInfo.TopY;
        startsAtBottom = false;
      }
      let newTopY = rightGable.topY;
      lastLeftGables = lastLeftGables.filter((llg) => llg.TopY > newTopY); //remove gables that are smaller than current gable
      lastLeftGables.push({
        X: rightGable.rightX,
        TopY: rightGable.topY,
        leftGable: rightGable,
      });
      lastLeftGables = lastLeftGables.sort((ga, gb) => ga.TopY - gb.TopY);
    }

    result = result.filter((r) => r.Width > 0 && r.Height > 0);
    return result;
  }

  public static getGableGaps(
    section: Client.CabinetSection,
    _options?: Partial<{
      excludeItems: Client.ConfigurationItem[];
      ignorePulloutSeverity: boolean;
      includeFittingPanels: boolean;
    }>,
  ): Client.GableGap[] {
    // handle option and defaults
    const defaultOptions = {
      excludeItems: [],
      ignorePulloutSeverity: false,
      includeFittingPanels: false,
    };
    const options = { ...defaultOptions, ..._options };

    if (section.CabinetType === Interface_Enums.CabinetType.Swing) {
      return InteriorLogic.getFreeGableGaps(
        section.swing.items
          .filter(
            (swingItem) =>
              swingItem.ItemType === Interface_Enums.ItemType.SwingCorpus ||
              swingItem.ItemType === Interface_Enums.ItemType.SwingFlexCorpus,
          )
          .concat(section.interior.items),
        options.excludeItems,
        section.cabinet.productLine,
      );
    } else if (section.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
      return InteriorLogic.getFreeSwingFlexGableGaps(
        section,
        options.excludeItems,
        section.cabinet.productLine,
        options.includeFittingPanels,
      );
    }

    let productLine = section.cabinet.productLine;
    let result: Client.GableGap[] = [];

    /** Anything that can be used as a wall for a gap.
     * Gables, fittingPanels, items from other sections, section boundaries, etc.
     * All gaps must have a left gapWall and a right gapWall */
    type GapWall = {
      X: number;
      rightX: number;
      Y: number;
      topY: number;
      item: Client.ConfigurationItem | null;
      type: 'gable' | 'fittingPanel' | 'interiorBoundary' | 'neighborItem';
      drilledLeft: boolean;
      drilledRight: boolean;
    };

    let includedItems = section.interior.items.filter(
      (i) => !options.excludeItems.includes(i),
    );

    let _gables = includedItems.filter(
      (i) => i.isGable && !i.isVerticalDivider && !i.isFittingPanel,
    );

    let gapWalls = _gables.map<GapWall>((gable) => ({
      X: gable.X,
      rightX: gable.rightX,
      Y: gable.Y,
      topY: gable.topY,
      item: gable,
      type: 'gable',
      drilledLeft: gable.Product
        ? ProductHelper.drilledLeft(gable.Product)
        : false,
      drilledRight: gable.Product
        ? ProductHelper.drilledRight(gable.Product)
        : false,
    }));

    if (options.includeFittingPanels) {
      const fittingPanels = Enumerable.from(section.interior.items)
        .where((i) => i.isFittingPanel)
        .select<GapWall>((panel) => ({
          X: panel.X,
          rightX: panel.rightX,
          Y: panel.Y,
          topY: panel.topY,
          item: panel,
          type: 'fittingPanel',
          drilledLeft: panel.Product
            ? ProductHelper.drilledLeft(panel.Product)
            : false,
          drilledRight: panel.Product
            ? ProductHelper.drilledRight(panel.Product)
            : false,
        }));

      gapWalls.push(...fittingPanels.toArray());
    }

    gapWalls.push(
      {
        X: section.interior.cube.X,
        rightX: section.interior.cube.X,
        Y: section.interior.cube.Y,
        topY: section.interior.cube.Y + section.interior.cube.Height,
        item: null,
        type: 'interiorBoundary',
        drilledLeft: false,
        drilledRight: true,
      },
      {
        X: section.interior.cube.X + section.interior.cube.Width,
        rightX: section.interior.cube.X + section.interior.cube.Width,
        Y: section.interior.cube.Y,
        topY: section.interior.cube.Y + section.interior.cube.Height,
        item: null,
        type: 'interiorBoundary',
        drilledLeft: true,
        drilledRight: false,
      },
    );

    if (section.leftNeighbor) {
      const neighborWidth = section.leftNeighbor.Width;
      const leftNeighborWalls = section.leftNeighbor.interior.items
        .filter((item) => item.snapsAgainstOpposite)
        .filter(
          (item) =>
            item.X <=
            neighborWidth -
              (section.interior.cube.Depth + section.interior.cube.Z),
        )
        .filter(
          (item) => item.rightX >= neighborWidth - section.interior.cube.Z,
        )
        .map<GapWall>((item) => {
          return {
            //we could include the item here, but it will probably lead to hard-to-discover
            // problems since the item is fron another section, and X- and Z-coordinates don't match
            item: null,
            X: item.Z,
            rightX: item.frontZ,
            Y: item.Y,
            topY: item.topY,
            type: 'neighborItem',
            drilledLeft: false,
            drilledRight: true,
          };
        });

      gapWalls.push(...leftNeighborWalls);
    }

    if (section.rightNeighbor) {
      const neighborWalls = section.rightNeighbor.interior.items
        .filter((item) => item.snapsAgainstOpposite)
        .filter((item) => item.X <= section.interior.cube.Z)
        .filter(
          (item) =>
            item.rightX >=
            section.interior.cube.Z + section.interior.cube.Depth,
        )
        .map<GapWall>((item) => ({
          item: null,
          X: section.Width - item.frontZ,
          rightX: section.Width - item.Z,
          type: 'neighborItem',
          Y: item.Y,
          topY: item.topY,
          drilledLeft: true,
          drilledRight: false,
        }));

      gapWalls.push(...neighborWalls);
    }

    for (let leftGapWall of gapWalls) {
      // start at the bottom right corner of the left wall
      // go right until you find another wall. If there isn't another wall, there is no gap...
      // go up until you reach the top of either the left or right gable, or
      // until you see another gable in between
      const x = leftGapWall.rightX;
      let y = leftGapWall.Y;

      while (y < leftGapWall.topY) {
        // go right until you find another wall.
        const rightGapWall = Enumerable.from(gapWalls)
          .where(
            (rgw) =>
              rgw !== leftGapWall &&
              rgw.X >= leftGapWall.rightX &&
              rgw.Y <= y &&
              rgw.topY > y,
          )
          .orderBy((rgw) => rgw.X)
          .firstOrDefault();
        if (!rightGapWall) {
          // If there isn't another wall, there is no gap...
          // try to find another wall further up, then use that Y-pos to find a gap
          const nextRightGap = Enumerable.from(gapWalls)
            .where(
              (gapWall) =>
                gapWall.X > x &&
                gapWall.Y >= y &&
                gapWall.Y <= leftGapWall.topY,
            )
            .orderBy((gapWall) => gapWall.Y)
            .firstOrDefault();
          if (nextRightGap) {
            y = nextRightGap.Y;
            continue;
          } else {
            break;
          }
        }
        const rightX = rightGapWall.X;
        let topY = Math.min(leftGapWall.topY, rightGapWall.topY);

        // try to find a wall between leftGapWall and rightGapWall
        const middleGapWall = Enumerable.from(gapWalls)
          .where(
            (mgw) =>
              mgw.X > x &&
              mgw.X < rightX &&
              mgw.Y > y &&
              mgw.Y < topY &&
              mgw !== leftGapWall &&
              mgw !== rightGapWall,
          )
          .orderBy((mgw) => mgw.Y)
          .firstOrDefault();
        if (middleGapWall) {
          topY = Math.min(topY, middleGapWall.Y);
        }

        if (leftGapWall.drilledRight && rightGapWall.drilledLeft) {
          result.push({
            Height: topY - y,
            leftGable: leftGapWall.item,
            rightGable: rightGapWall.item,
            startsAtBottom: y <= section.interior.cube.Y,
            Width: rightX - x,
            X: x,
            Y: y,
            isFittingPanel:
              leftGapWall.type == 'fittingPanel' ||
              rightGapWall.type === 'fittingPanel',
            pulloutSeverity: options.ignorePulloutSeverity
              ? Enums.PulloutWarningSeverity.None
              : this.getMaxSeverity(
                  section.doors.pulloutWarningAreas,
                  x,
                  rightX,
                ),
            drillStops: this.getDrillStops(
              productLine,
              section.interior.cube.Y,
              topY,
              y,
            ),
          });
        }

        //prepare for nex loop iteration
        y = topY;
      }
    }

    result = result.filter((r) => r.Width > 0 && r.Height > 0);

    return result;
  }

  public static getFreeSwingFlexGableGaps(
    section: Client.CabinetSection,
    excludeItems: Client.ConfigurationItem[],
    productLine: Interface_DTO.ProductLine,
    includeFittingPanels: boolean = false,
  ): Client.GableGap[] {
    const swingFlexBottomOffset: number = section.swingFlex.getGablePositionY();

    let items = Enumerable.from(section.swingFlex.items)
      .concat(section.interior.items)
      .toArray() as Client.ConfigurationItem[];

    let result: Client.GableGap[] = [];
    let includedItems = items.filter((i) => excludeItems.indexOf(i) < 0);

    let gables = includedItems.filter(
      (i) => i.isGable && !i.isVerticalDivider && !i.isFittingPanel,
    );

    if (includeFittingPanels) {
      gables = gables.concat(
        section.interior.items.filter((i) => i.isFittingPanel),
      );
    }

    gables = gables.sort((i1, i2) => i1.X - i2.X);

    let lastLeftGables: {
      X: number;
      TopY: number;
      leftGable: Client.ConfigurationItem | null;
    }[] = [
      {
        X: section.interior.cube.X,
        TopY: section.interior.cube.Y + section.interior.cube.Height,
        leftGable: null,
      },
    ];

    //get gaps at ground level
    for (let gable of gables.filter((e) => !e.isVerticalDivider)) {
      let lastY = section.interior.cube.Y;
      let startsAtBottom = true;
      let _isFittingPanel = gable.isFittingPanel;

      for (let gableInfo of lastLeftGables) {
        let pos = {
          X: gableInfo.X,
          Y: lastY,
        };
        let size = {
          X: gable.X - gableInfo.X,
          Y: Math.min(gableInfo.TopY, gable.Y + gable.Height),
        };
        let end = pos.X + size.X;
        result.push({
          ...pos,
          Width: size.X,
          Height: size.Y,
          pulloutSeverity: false
            ? Enums.PulloutWarningSeverity.None
            : this.getMaxSeverity(
                section.doors.pulloutWarningAreas,
                pos.X,
                end,
              ),
          leftGable: gableInfo.leftGable,
          rightGable: gable,
          drillStops: this.getDrillStops(
            productLine,
            swingFlexBottomOffset,
            size.Y,
            lastY,
          ),
          startsAtBottom: startsAtBottom,
          isFittingPanel: _isFittingPanel,
        });
        lastY = gableInfo.TopY;
        startsAtBottom = false;
      }
      let newTopY = gable.Y + gable.Height;
      lastLeftGables = lastLeftGables.filter((llg) => llg.TopY > newTopY); //remove gables that are smaller than current gable
      lastLeftGables.push({
        X: gable.rightX,
        TopY: gable.topY,
        leftGable: gable,
      });
      lastLeftGables = lastLeftGables.sort((ga, gb) => ga.TopY - gb.TopY);
    }
    let lastY = section.interior.cube.Y;
    let startsAtBottom = true;

    for (let gableInfo of lastLeftGables) {
      //do the space between the last gable and the outer right corpus
      let pos = {
        X: gableInfo.X,
        Y: lastY,
      };
      let size = {
        X: section.interior.cube.X + section.interior.cube.Width - gableInfo.X,
        Y: Math.min(
          gableInfo.TopY,
          section.interior.cube.Y + section.interior.cube.Height,
        ),
      };
      let lastEnd = section.interior.cube.X + section.interior.cube.Width;

      result.push({
        ...pos,
        Width: size.X,
        Height: size.Y,
        pulloutSeverity: this.getMaxSeverity(
          section.doors.pulloutWarningAreas,
          pos.X,
          lastEnd,
        ),
        leftGable: gableInfo.leftGable,
        rightGable: null,
        drillStops: this.getDrillStops(
          productLine,
          swingFlexBottomOffset,
          size.Y,
          lastY,
        ),
        startsAtBottom: startsAtBottom,
        isFittingPanel: gableInfo.leftGable?.isFittingPanel,
      });
      lastY = gableInfo.TopY;
      startsAtBottom = false;
    }

    result = result.filter((r) => r.Width > 0 && r.Height > 0);

    return result;
  }

  public static getFittingPanelGaps(section: Client.CabinetSection) {
    let result: Client.GableGap[] = [];

    let gables = Enumerable.from(section.interior.items)
      .where((i) => i.isGable && !i.isFittingPanel)
      .orderBy((i) => i.X);

    let fittingPanels = Enumerable.from(section.interior.items)
      .where((i) => i.isFittingPanel)
      .orderBy((i) => i.X);

    let productLine = section.cabinet.productLine;

    let fittingPanelGableInSameHeight = fittingPanels.groupBy((c) => c.Y);

    fittingPanelGableInSameHeight.forEach((fittingPanel) => {
      let leftFittingGable =
        fittingPanel.firstOrDefault((c) => c.drilledRight) ?? null;

      let rightFittingGable =
        fittingPanel.firstOrDefault((c) => c.drilledLeft) ?? null;

      let pos = undefined;
      let size = undefined;
      let lastY = 0;

      if (leftFittingGable && rightFittingGable) {
        pos = {
          X: leftFittingGable.rightX,
          Y: leftFittingGable.bottomY,
        };
        size = {
          X: rightFittingGable.leftX - leftFittingGable.rightX,
          Y: leftFittingGable.topY,
        };
        lastY = leftFittingGable.Y;
      } else if (leftFittingGable && !rightFittingGable) {
        pos = {
          X: leftFittingGable.rightX,
          Y: leftFittingGable.bottomY,
        };
        size = {
          X:
            section.sightOffsetX + section.SightWidth - leftFittingGable.rightX,
          Y: leftFittingGable.topY,
        };

        const gableNext = gables.firstOrDefault(
          (g) => g.X > leftFittingGable.rightX,
        );
        if (gableNext) {
          size.X = gableNext.X - leftFittingGable.rightX;
        }
        lastY = leftFittingGable.Y;
      } else if (!leftFittingGable && rightFittingGable) {
        pos = {
          X: section.sightOffsetX + section.SightWidth,
          Y: rightFittingGable.Y,
        };
        size = {
          X: rightFittingGable.X - pos.X,
          Y: rightFittingGable.topY,
        };

        const gablePrevious = gables
          .where((g) => g.rightX < rightFittingGable.leftX)
          .orderByDescending((g) => g.rightX)
          .firstOrDefault();

        if (gablePrevious) {
          pos.X = gablePrevious.rightX;
          size.X = rightFittingGable.X - gablePrevious.rightX;
        }

        lastY = rightFittingGable.Y;
      }

      if (!pos || !size) {
        return;
      }

      result.push({
        ...pos,
        Width: size.X,
        Height: size.Y,
        pulloutSeverity: Enums.PulloutWarningSeverity.None,
        leftGable: leftFittingGable,
        rightGable: rightFittingGable,
        drillStops: this.getDrillStops(
          productLine,
          section.interior.cube.Y,
          size.Y,
          lastY,
        ),
        startsAtBottom: true,
        isFittingPanel: true,
      });
    });

    return result;
  }

  public static getSwingFlexFittingPanelGaps(section: Client.CabinetSection) {
    let result: Client.GableGap[] = [];

    let gables = section.interior.items
      .filter((i) => i.isGable && i.isFittingPanel)
      .sort((i1, i2) => i1.X - i2.X);

    let productLine = section.cabinet.productLine;

    let gableBetween = Enumerable.from(section.swingFlex.areas)
      .selectMany((a) => a.items)
      .firstOrDefault((c) => c.isGable && c.drilledRight && c.drilledLeft);

    section.swingFlex.areas.forEach((area) => {
      const swingFlexBottomOffset: number =
        section.swingFlex.getGablePositionY();

      area.subAreas.forEach((subArea) => {
        let gableInsideArea = Enumerable.from(gables).where(
          (c) =>
            c.X >= subArea.insideRect.X &&
            c.X <= subArea.insideRect.X + subArea.insideRect.Width,
        );

        let gableInSameHeight = gableInsideArea.groupBy((c) => c.Y);

        gableInSameHeight.forEach((gable) => {
          let leftFittingGable =
            gable.firstOrDefault((c) => c.drilledRight) ?? null;
          let rightFittingGable =
            gable.firstOrDefault((c) => c.drilledLeft) ?? null;

          let pos = undefined;
          let size = undefined;
          let lastY = area.insideRect.Y;

          if (leftFittingGable && rightFittingGable) {
            pos = {
              X: leftFittingGable.rightX,
              Y: leftFittingGable.bottomY,
            };
            size = {
              X: rightFittingGable.leftX - leftFittingGable.rightX,
              Y: leftFittingGable.topY,
            };
            lastY = leftFittingGable.Y;
          } else if (leftFittingGable && !rightFittingGable) {
            pos = {
              X: leftFittingGable.rightX,
              Y: leftFittingGable.bottomY,
            };
            size = {
              X:
                subArea.insideRect.Width -
                (leftFittingGable.rightX - subArea.insideRect.X),
              Y: leftFittingGable.topY,
            };
            lastY = leftFittingGable.Y;
          } else if (!leftFittingGable && rightFittingGable) {
            pos = {
              X: subArea.insideRect.X,
              Y: rightFittingGable.Y,
            };
            size = {
              X: rightFittingGable.X - subArea.insideRect.X,
              Y: rightFittingGable.topY,
            };
            lastY = rightFittingGable.Y;
          }

          if (!pos || !size) {
            return;
          }

          if (!leftFittingGable) {
            leftFittingGable = gableBetween ?? null;
          }

          if (!rightFittingGable) {
            rightFittingGable = gableBetween ?? null;
          }

          result.push({
            ...pos,
            Width: size.X,
            Height: size.Y,
            pulloutSeverity: Enums.PulloutWarningSeverity.None,
            leftGable: leftFittingGable,
            rightGable: rightFittingGable,
            drillStops: this.getDrillStops(
              productLine,
              swingFlexBottomOffset,
              size.Y,
              lastY,
            ),
            startsAtBottom: true,
            isFittingPanel: true,
          });
        });
      });
    });

    return result;
  }

  public static getDrillStops(
    productLine: Interface_DTO.ProductLine,
    startOffset: number,
    maxHeight: number,
    minHeight = 0,
  ): number[] {
    const result: number[] = [];
    const startY =
      productLine.GableDrillingStart +
      startOffset -
      productLine.GableDrillingSpacing;

    for (
      let y = startY;
      y <= maxHeight;
      y += productLine.GableDrillingSpacing
    ) {
      if (y > minHeight) result.push(y);
    }

    return result;
  }

  public static getFirstDrillStop(
    productLine: Interface_DTO.ProductLine,
    startOffset: number,
    maxHeight: number,
    minHeight = 0,
  ): number[] {
    const result: number[] = [];
    let drillStops = this.getDrillStops(
      productLine,
      startOffset,
      maxHeight,
      minHeight,
    );
    if (drillStops.length > 0) result.push(drillStops[0]);
    return result;
  }

  private static getMaxSeverity(
    pulloutAreas: Client.PulloutWarningArea[],
    start: number,
    end: number,
  ): Enums.PulloutWarningSeverity {
    let relvantAreas = pulloutAreas.filter((pa) => pa.overlaps(start, end));
    return Math.max(
      Enums.PulloutWarningSeverity.None,
      ...relvantAreas.map((pa) => pa.severity),
    );
  }

  /** Most gaps supports pullout, but they are not supported inside Swing cabinets
   * unless they are inside a pullout module.
   * Pullout items would hit the side of the door if not placed in a pullout submodule.*/
  public static supportsPullout(
    gap: Client.GableGap | Client.InteriorGap,
  ): boolean {
    for (let gable of [gap.leftGable, gap.rightGable]) {
      if (!gable) return true;
      if (!gable.isSwingCorpusGable) return true;
    }
    return false;
  }

  private adjustSwingFlexFittingPanelYZPositions(
    section: Client.CabinetSection,
  ) {
    // Adjust the Y position and Height of all FittingPanels

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _shelfs = Enumerable.from(section.swingFlex.items)
      .concat(section.interior.items)
      .where((i) => i.isShelf_19_22)
      .orderBy((i) => i.Y)
      .asEnumerable();

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _topShelf = Enumerable.from(section.interior.items)
      .where((i) => i.isShelf_19_22)
      .orderByDescending((i) => i.Y)
      .firstOrDefault();

    section.interior.items
      .filter((i) => i.isFittingPanel)
      .forEach((gable) => {
        const area = Enumerable.from(section.swingFlex.areas).firstOrDefault(
          (area) => area.index === gable.swingFlexAreaIndex,
        );

        if (!area) return;

        let _findShelfInArea = _shelfs.where(
          (s) => s.swingFlexAreaIndex === gable.swingFlexAreaIndex,
        );
        if (_findShelfInArea.any()) {
          let shelfClosestToBottom = _findShelfInArea.minBy((c) =>
            Math.abs(c.centerY - gable.bottomY),
          );
          if (shelfClosestToBottom) {
            const shelfClosestToTop = _findShelfInArea.minBy((c) =>
              Math.abs(c.bottomY - gable.topY),
            );

            if (shelfClosestToTop && shelfClosestToBottom) {
              gable.Y = shelfClosestToBottom.topY;

              gable.trySetHeight(
                shelfClosestToTop.bottomY - shelfClosestToBottom.topY,
              );

              if (_topShelf) {
                gable.Z = _topShelf.Z;
                gable.trySetDepth(_topShelf.Depth);
              }
            }
          }
        }
      });
  }

  private adjustFittingPanelXPositions(section: Client.CabinetSection) {
    // Adjust the X position of all FittingsPanels
    let _fittingPanelSpacerWidth = 0;

    let productLine = Enumerable.from(section.editorAssets.productLines).single(
      (pl) => pl.Id === section.cabinet.ProductLineId,
    );
    if (productLine) {
      _fittingPanelSpacerWidth = productLine.Properties.FittingPanelSpacerWidth;
    }

    let _fittingPanels = Enumerable.from(section.interior.items)
      .where((i) => i.isFittingPanel)
      .orderBy((i) => i.X)
      .asEnumerable();

    _fittingPanels.forEach((gable) => {
      if (gable.drilledLeft) {
        let _closestGable = this.findClosestAreaGable(
          section,
          gable,
          Enums.Side.Right,
          undefined,
          true,
        );
        if (_closestGable) {
          gable.X =
            _closestGable.leftX - (gable.Width + _fittingPanelSpacerWidth);
        }
      } else if (gable.drilledRight) {
        let _closestGable = this.findClosestAreaGable(
          section,
          gable,
          Enums.Side.Left,
          undefined,
          true,
        );
        if (_closestGable) {
          gable.X = _closestGable.rightX + _fittingPanelSpacerWidth;
        }
      }
    });

    if (section.backing.backingType === BackingType.None) {
      section.backing.setBackingType(BackingType.Visible, false);
    }
  }

  private adjustFittingPanelYZPositions(section: Client.CabinetSection) {
    // Adjust the Y position and Height of all FittingPanels

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _shelfs = Enumerable.from(section.interior.items)
      .where((i) => i.isShelf_19_22)
      .orderBy((i) => i.Y)
      .asEnumerable();

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _topShelf = Enumerable.from(section.interior.items)
      .where((i) => i.isShelf_19_22)
      .orderByDescending((i) => i.Y)
      .firstOrDefault();

    if (section.corpus.itemsTop.length > 0) {
      _topShelf = section.corpus.itemsTop.find(
        (i) => Math.abs(i.Depth - section.Depth) < 20,
      );
    }

    section.interior.items
      .filter((i) => i.isFittingPanel)
      .forEach((gable) => {
        let _findShelfInArea = _shelfs.where(
          (s) => s.X < gable.centerX && s.rightX > gable.centerX,
        );

        _findShelfInArea = _findShelfInArea.concat(
          section.corpus.itemsBottom.filter(
            (i) => Math.abs(i.Depth - section.Depth) < 20,
          ),
        );
        _findShelfInArea = _findShelfInArea.concat(
          section.corpus.itemsTop.filter(
            (i) => Math.abs(i.Depth - section.Depth) < 20,
          ),
        );

        if (_findShelfInArea.any()) {
          let shelfClosestToBottom = _findShelfInArea
            .orderBy((c) => Math.abs(c.centerY - gable.bottomY))
            .firstOrDefault();

          if (shelfClosestToBottom) {
            const shelfClosestToTop = _findShelfInArea.minBy((c) =>
              Math.abs(c.bottomY - gable.topY),
            );

            if (
              shelfClosestToTop &&
              shelfClosestToTop !== shelfClosestToBottom
            ) {
              gable.Y = shelfClosestToBottom.topY;

              gable.trySetHeight(
                shelfClosestToTop.bottomY - shelfClosestToBottom.topY,
              );

              if (_topShelf) {
                gable.Z = _topShelf.Z;
                gable.trySetDepth(_topShelf.Depth);
              }
            }
          }
        }
      });
  }

  private adjustItemToGable(section: Client.CabinetSection) {
    let spaceForBacking = 0;
    let fittingPanelSpacerWidth = 0;

    let productLine = Enumerable.from(section.editorAssets.productLines).single(
      (pl) => pl.Id === section.cabinet.ProductLineId,
    );
    if (productLine) {
      spaceForBacking = productLine.Properties.SwingFlexSpaceForBacking;
      fittingPanelSpacerWidth = productLine.Properties.FittingPanelSpacerWidth;
    }

    const areaDepth = section.swingFlex.depth - spaceForBacking;

    let _items = Enumerable.from(section.interior.items)
      .orderBy((i) => i.X)
      .asEnumerable();

    _items.forEach((item) => {
      if (!item.Product) {
        return;
      }
      let productGroup = Enumerable.from(item.Product.productGroup);

      let productListOrderByMax = productGroup
        .orderByDescending((prod) =>
          ProductHelper.maxDepth(prod, item.productLineId),
        )
        .thenByDescending((prod) => ProductHelper.maxWidth(prod));

      if (item.swingFlexAreaIndex !== undefined) {
        let swingFlexArea = section.swingFlex.areas[item.swingFlexAreaIndex];

        // Find the closest left gable to the swing flex area
        let closestGable = Enumerable.from(section.swingFlex.items)
          .concat(section.interior.items)
          .firstOrDefault(
            (i) =>
              i.isGable &&
              !i.isVerticalDivider &&
              !i.isFittingPanel &&
              Math.abs(i.centerX - swingFlexArea.insideRect.X) < 20,
          );

        if (closestGable) {
          const frontZ = closestGable.frontZ;
          const desiredItemDepth = areaDepth - item.depthReduction;

          if (item.isFittingPanel) {
            let productClosestDepth = this.findProductClosestDepth(
              productListOrderByMax.toArray(),
              item.productLineId,
              desiredItemDepth,
            );
            if (productClosestDepth) {
              let maxDepth = ProductHelper.maxDepth(
                productClosestDepth,
                item.ProductId,
              );

              item.Product = productClosestDepth;

              if (item.productLineId) {
                item.trySetDepth(
                  desiredItemDepth < maxDepth &&
                    ProductHelper.isFlexDepth(
                      productClosestDepth,
                      item.productLineId,
                    )
                    ? desiredItemDepth
                    : maxDepth,
                );
              }

              item.Z = frontZ - item.depthReduction - item.Depth;
            }

            if (item.drilledLeft) {
              item.X =
                swingFlexArea.insideRect.X +
                swingFlexArea.insideRect.Width -
                (fittingPanelSpacerWidth + item.Width);
            } else if (item.drilledRight) {
              item.X = swingFlexArea.insideRect.X + fittingPanelSpacerWidth;
            }

            return;
          }

          let gableDistance = swingFlexArea.insideRect.Width;
          let rightX = swingFlexArea.insideRect.X;

          if (
            swingFlexArea.middlePanelItem &&
            item.Y < swingFlexArea.middlePanelItem.topY
          ) {
            if (item.centerX > swingFlexArea.middlePanelItem.centerX) {
              gableDistance =
                swingFlexArea.insideRect.RightX -
                swingFlexArea.middlePanelItem.rightX;
            } else {
              gableDistance =
                swingFlexArea.middlePanelItem.X - swingFlexArea.insideRect.X;
            }
          }

          const fittingPanelsInArea = _items.where(
            (c) =>
              c.swingFlexAreaIndex === swingFlexArea.index &&
              c.isFittingPanel &&
              c.Y < item.Y &&
              c.topY > item.topY,
          );
          if (fittingPanelsInArea.any()) {
            const fittingPanelLeft = fittingPanelsInArea.firstOrDefault(
              (c) => c.drilledRight,
            );

            if (fittingPanelLeft) {
              rightX = fittingPanelLeft.rightX;
            }

            const fittingPanelRight = fittingPanelsInArea.firstOrDefault(
              (c) => c.drilledLeft,
            );

            if (fittingPanelRight && fittingPanelLeft) {
              gableDistance =
                swingFlexArea.insideRect.Width -
                (fittingPanelSpacerWidth + fittingPanelLeft.Width) * 2;
            } else if (fittingPanelRight && !fittingPanelLeft) {
              gableDistance =
                swingFlexArea.insideRect.Width -
                (fittingPanelSpacerWidth + fittingPanelRight.Width);
            } else if (!fittingPanelRight && fittingPanelLeft) {
              gableDistance =
                swingFlexArea.insideRect.Width -
                (fittingPanelSpacerWidth + fittingPanelLeft.Width);
            }
          }

          let productListWithinWidth = productListOrderByMax.where((prod) =>
            ProductHelper.supportsWidth(prod, gableDistance),
          );

          if (productListWithinWidth.any()) {
            let drillingPattern = section.isSwingFlex
              ? section.swingFlex.getDrillingPattern()
              : undefined;
            let productClosestDepth = this.findProductClosestDepth(
              productListWithinWidth.toArray(),
              item.productLineId,
              desiredItemDepth,
              drillingPattern,
            );
            if (productClosestDepth) {
              if (item.snapDepthMiddle) {
                let product = productListWithinWidth
                  .orderBy((p) => p.SortOrder)
                  .firstOrDefault();
                if (product) item.Product = product;
                item.Z =
                  closestGable.centerZ - item.depthReduction - item.Depth / 2;
              } else {
                // !item.snapDepthMiddle
                let maxDepth = ProductHelper.maxDepth(
                  productClosestDepth,
                  item.ProductId,
                );

                item.Product = productClosestDepth;
                if (item.productLineId) {
                  item.trySetDepth(
                    desiredItemDepth < maxDepth &&
                      ProductHelper.isFlexDepth(
                        productClosestDepth,
                        item.productLineId,
                      )
                      ? desiredItemDepth
                      : maxDepth,
                  );
                }

                item.Z = frontZ - item.depthReduction - item.Depth;
              }

              if (swingFlexArea.middlePanelItem) {
                if (item.X < swingFlexArea.middlePanelItem.X) {
                  rightX = swingFlexArea.insideRect.X;
                } else {
                  rightX = swingFlexArea.middlePanelItem.rightX;
                }
              }

              item.X = rightX;
              item.trySetWidth(gableDistance, false, true);
            }
          }
        }
      }
    });
  }

  private adjustVerticalDivider(section: Client.CabinetSection) {
    let _shelfs = Enumerable.from(section.interior.items)
      .concat(section.swingFlex.items)
      .where((i) => i.isShelf_19_22)
      .orderBy((i) => i.Y)
      .asEnumerable();

    section.interior.items
      .filter((i) => i.isVerticalDivider)
      .forEach((verticalDivider) => {
        let _findShelfInArea = _shelfs.where(
          (s) =>
            s.leftX <= verticalDivider.centerX &&
            s.rightX >= verticalDivider.centerX,
        );
        if (_findShelfInArea.any()) {
          let shelfClosestToBottom = _findShelfInArea
            .where((s) => s.topY < section.Height)
            .orderBy((s) => Math.abs(s.topY - verticalDivider.bottomY))
            .firstOrDefault();

          let shelfClosestToTop = _findShelfInArea
            .where(
              (s) =>
                s.topY > verticalDivider.bottomY && s !== shelfClosestToBottom,
            )
            .orderBy((s) => Math.abs(s.bottomY - verticalDivider.topY))
            .firstOrDefault();

          let shelfDepth: number = 0;
          let shelfZ: number = 0;

          if (!shelfClosestToBottom && shelfClosestToTop) {
            shelfDepth = shelfClosestToTop.Depth;
            shelfZ = shelfClosestToTop.Z;

            verticalDivider.Y = section.interior.cube.Y;
            verticalDivider.trySetHeight(
              shelfClosestToTop.bottomY - section.interior.cube.Y,
              true,
            );
          } else if (shelfClosestToBottom && shelfClosestToTop) {
            shelfDepth = Math.max(
              shelfClosestToBottom.Depth,
              shelfClosestToTop.Depth,
            );
            shelfZ = Math.min(shelfClosestToBottom.Z, shelfClosestToTop.Z);

            verticalDivider.Y = shelfClosestToBottom.topY;
            verticalDivider.trySetHeight(
              shelfClosestToTop.bottomY - shelfClosestToBottom.topY,
              true,
            );
          }

          if (!verticalDivider.Product) {
            return;
          }

          const desiredItemDepth = shelfDepth - verticalDivider.depthReduction;

          verticalDivider.Z = shelfZ;
          verticalDivider.trySetDepth(desiredItemDepth);
        }
      });
  }

  private adjustItemToFittingPanel(section: Client.CabinetSection) {
    if (section.isSwingFlex) {
      return;
    }

    const fittingPanels = Enumerable.from(section.interior.items)
      .where((i) => i.isFittingPanel)
      .orderBy((i) => i.X)
      .asEnumerable();

    if (!fittingPanels.any()) {
      return;
    }

    const interiors = Enumerable.from(section.interior.items)
      .where((i) => !i.isFittingPanel && !i.isGable)
      .orderBy((i) => i.X)
      .asEnumerable();

    let gables = Enumerable.from(section.interior.items)
      .where((i) => i.isGable && !i.isFittingPanel)
      .orderBy((i) => i.X)
      .asEnumerable();

    gables = gables.concat(
      section.corpus.itemsLeft.filter(
        (i) => Math.abs(i.Depth - section.Depth) < 20,
      ),
    );
    gables = gables.concat(
      section.corpus.itemsRight.filter(
        (i) => Math.abs(i.Depth - section.Depth) < 20,
      ),
    );

    gables = gables.orderBy((i) => i.X);

    if (!interiors.any()) {
      return;
    }

    interiors.forEach((interior) => {
      let fittingPanelsInSameY = fittingPanels.where(
        (fp) => fp.Y < interior.centerY && fp.topY > interior.centerY,
      );

      if (fittingPanelsInSameY.any()) {
        const leftFittingGable = fittingPanelsInSameY
          .where((fp) => fp.drilledRight)
          .orderBy((fp) => Math.abs(fp.rightX - interior.leftX))
          .firstOrDefault();

        const rightFittingGable = fittingPanelsInSameY
          .where((fp) => fp.drilledLeft)
          .orderBy((fp) => Math.abs(fp.X - interior.rightX))
          .firstOrDefault();

        if (
          leftFittingGable &&
          Math.abs(leftFittingGable.rightX - interior.X) < 100 &&
          rightFittingGable &&
          Math.abs(rightFittingGable.X - interior.rightX) < 100
        ) {
          interior.X = leftFittingGable.rightX;
          interior.trySetWidth(rightFittingGable.X - interior.X, true, true);
        } else if (
          leftFittingGable &&
          Math.abs(leftFittingGable.rightX - interior.X) < 100 &&
          !rightFittingGable
        ) {
          const closestGableRight = gables.minBy((g) =>
            Math.abs(g.X - interior.rightX),
          );

          interior.X = leftFittingGable.rightX;
          interior.trySetWidth(closestGableRight.X - interior.X, true, true);
        } else if (
          !leftFittingGable &&
          rightFittingGable &&
          Math.abs(rightFittingGable.X - interior.rightX) < 100
        ) {
          const closestGable = gables.minBy((g) =>
            Math.abs(g.rightX - interior.X),
          );

          interior.X = closestGable.rightX;
          interior.trySetWidth(rightFittingGable.X - interior.X, true, true);
        }
      }
    });
  }

  findProductClosestDepth(
    productList: Client.Product[],
    productLineId: Interface_Enums.ProductLineId | null,
    desiredDepth: number,
    drillingPattern?: Interface_Enums.DrillingPattern,
  ): Client.Product | undefined {
    let product: Client.Product | undefined;

    if (!productLineId) {
      return;
    }

    let filteredProducts = !!drillingPattern
      ? productList.filter((p) =>
          ProductHelper.supportsDrillingPattern(p, drillingPattern),
        )
      : productList;

    for (let prod of filteredProducts) {
      if (ProductHelper.defaultDepth(prod, productLineId) === desiredDepth) {
        product = prod;
        break;
      }
    }

    if (product) {
      return product;
    }

    let closestDepth = Number.MAX_VALUE;

    for (let prod of filteredProducts) {
      if (!ProductHelper.isFlexDepth(prod, productLineId)) {
        let depthDiff =
          desiredDepth - ProductHelper.defaultDepth(prod, productLineId);
        if (depthDiff > 0 && depthDiff < closestDepth) {
          closestDepth =
            desiredDepth - ProductHelper.defaultDepth(prod, productLineId);
          product = prod;
        }
      }
    }

    if (product) {
      return product;
    }

    let closestDiff = Number.MAX_VALUE;

    for (let prod of filteredProducts) {
      let maxDepth = ProductHelper.maxDepth(prod, productLineId);
      let minDepth = ProductHelper.minDepth(prod, productLineId);

      let minMaxDiff = Math.min(
        Math.abs(desiredDepth - maxDepth),
        Math.abs(desiredDepth - minDepth),
      );
      if (minMaxDiff < closestDiff) {
        closestDiff = minMaxDiff;
        product = prod;
      }
    }

    return product;
  }

  private findClosestAreaGable(
    section: Client.CabinetSection,
    gable: Client.ConfigurationItem,
    direction: Enums.Side,
    currentItem: Client.ConfigurationItem | undefined,
    skipFittingPanel: boolean = false,
  ): Client.ConfigurationItem | undefined {
    let _gable: Client.ConfigurationItem | undefined = undefined;
    let distance = 9999;

    if (direction === Enums.Side.Left) {
      let _leftGables = Enumerable.from(section.swingFlex.items)
        .concat(section.interior.items)
        .where(
          (i) =>
            i.isGable && i.drilledRight && !i.isVerticalDivider && i != gable,
        )
        .orderBy((i) => i.X)
        .asEnumerable();

      _leftGables.forEach((g) => {
        if (skipFittingPanel && g.isFittingPanel) {
          return;
        }

        let _distance = Math.abs(gable.leftX - g.rightX);
        if (_distance < distance) {
          distance = _distance;
          _gable = g;
        }
      });
    } else if (direction === Enums.Side.Right) {
      let _rightGables = Enumerable.from(section.swingFlex.items)
        .concat(section.interior.items)
        .where(
          (i) =>
            i.isGable &&
            i.drilledLeft &&
            !i.isVerticalDivider &&
            i != gable &&
            i.X > gable.X,
        )
        .orderBy((i) => i.X)
        .asEnumerable();

      _rightGables.forEach((g) => {
        if (skipFittingPanel && g.isFittingPanel) {
          return;
        }

        if (
          currentItem &&
          (g.topY < currentItem.bottomY || g.bottomY > currentItem.topY)
        ) {
          return;
        }

        let _distance = Math.abs(gable.rightX - g.leftX);
        if (_distance < distance) {
          distance = _distance;
          _gable = g;
        }
      });
    }

    return _gable;
  }

  private static findClosestDrillStop(
    gableBetweenDrillStops: number[],
    p0: number,
  ): number {
    let _closestDrillStop = gableBetweenDrillStops.reduce((prev, curr) => {
      return Math.abs(curr - p0) < Math.abs(prev - p0) ? curr : prev;
    });

    return _closestDrillStop;
  }

  private replaceAutoGeneratedItems(section: Client.CabinetSection) {
    this.replaceGrips(section);
  }

  private replaceGrips(section: Client.CabinetSection) {
    section.interior.items = section.interior.items.filter(
      (item) => !item.isGrip,
    );

    const grips: Client.ConfigurationItem[] = [];
    for (const item of section.interior.items) {
      const grip = this.configurationItemService.createDrawerGripItem(
        item,
        Interface_Enums.ItemType.Interior,
      );
      if (!grip) continue;
      if (!grip.isGrip) {
        console.error('Interior item produced a grip that is not a grip', {
          item,
          grip,
        });
        continue;
      }
      grips.push(grip);
    }
    section.interior.items.push(...grips);
  }

  private adjustItemDepthsAndZ(section: Client.CabinetSection) {
    const itemsToAdjust = section.interior.items.filter(
      (i) => !i.IsLocked && (i.snapLeftAndRight || i.snapLeft || i.snapRight),
    );
    for (let item of itemsToAdjust) {
      let leftGable = section.interior.items.find(
        (gable) =>
          gable.isGable &&
          gable.rightX === item.X &&
          gable.Y <= item.Y &&
          gable.topY >= item.topY &&
          (item.snapLeftAndRight || item.snapLeft),
      );
      let rightGable = section.interior.items.find(
        (gable) =>
          gable.isGable &&
          gable.X === item.rightX &&
          gable.Y <= item.Y &&
          gable.topY >= item.topY &&
          (item.snapLeftAndRight || item.snapRight),
      );
      if (!leftGable && !rightGable) {
        continue;
      }
      let gableFrontZ = Math.min(
        leftGable?.frontZ ?? Infinity,
        rightGable?.frontZ ?? Infinity,
      );
      let gableBackZ = Math.max(
        leftGable?.Z ?? -Infinity,
        rightGable?.Z ?? -Infinity,
      );
      let gableDepth = gableFrontZ - gableBackZ;

      let itemFrontZ = gableFrontZ - item.depthReduction;
      let itemBackZ = gableBackZ;
      let itemDepth = itemFrontZ - itemBackZ;

      if (!item.isFlexDepth && item.snapDepthMiddle) {
        let newZ = (itemFrontZ - itemBackZ) / 2 - item.Depth / 2;
        item.Z = newZ;
      } else {
        const drillingPatterns =
          section.cabinet.productLine.Properties.DrillingPatterns;
        let drillingPattern =
          Enumerable.from(drillingPatterns)
            .where((dp) => gableDepth < dp.MaxDepthExclusive)
            .orderByDescending((dp) => dp.MaxDepthExclusive)
            .select((dp) => dp.DrillingPattern)
            .firstOrDefault() ??
          section.cabinet.productLine.Properties.DefaultDrillingPattern;

        if (
          item.trySetDepth(itemDepth, true, true, {
            productSelectionFilter: (product) =>
              ProductHelper.supportsDrillingPattern(product, drillingPattern),
          })
        ) {
          item.Z = gableFrontZ - item.Depth - item.depthReduction;
        }
      }
    }
  }
}
