<svg:g
  [attr.transform]="itemTransformString"
  (mousedown)="mouseDown.emit($event)"
  (touchstart)="mouseDown.emit($event)"
  [attr.data-description]="item.Description"
  [attr.data-render-method]="renderMethod"
  [ngClass]="{ 'grip-item': item.isGrip }"
>
  <g *ngIf="!item.isTemplate && showItem">
    <g class="item-image" [attr.transform]="mirrorTransformString">
      <image
        *ngIf="renderMethod === 'model2D'"
        preserveAspectRatio="none"
        href=""
        [attr.href]="item.getModel2DPath()"
        width="0"
        [attr.width]="item.visualWidth"
        height="0"
        [attr.height]="item.Height"
      />

      <g *ngIf="renderMethod === 'material2'">
        <defs>
          <!-- A tiling pattern for the material.
           In order to support reflextion on the x- and y-axis, there are 4 copies of the material -->
          <pattern
            [attr.id]="idPostfix"
            [attr.width]="materialWidth * 2"
            [attr.height]="materialHeight * 2"
            patternUnits="userSpaceOnUse"
          >
            <g [attr.transform]="materialRotateTransform">
              <g [attr.transform]="getMaterial2TransformString(false, false)">
                <image
                  class="item-material2 mat2-br"
                  preserveAspectRatio="none"
                  href=""
                  x="0"
                  y="0"
                  [attr.href]="material2Path"
                  width="0"
                  [attr.width]="item.Material!.TextureWidthMm"
                  height="0"
                  [attr.height]="item.Material!.TextureHeightMm"
                />
              </g>
              <g [attr.transform]="getMaterial2TransformString(true, false)">
                <image
                  class="item-material2 mat2-bl"
                  preserveAspectRatio="none"
                  href=""
                  [attr.href]="material2Path"
                  width="0"
                  [attr.width]="item.Material!.TextureWidthMm"
                  height="0"
                  [attr.height]="item.Material!.TextureHeightMm"
                />
              </g>
              <g [attr.transform]="getMaterial2TransformString(true, true)">
                <image
                  class="item-material2 mat2-tl"
                  preserveAspectRatio="none"
                  href=""
                  [attr.href]="material2Path"
                  width="0"
                  [attr.width]="item.Material!.TextureWidthMm"
                  height="0"
                  [attr.height]="item.Material!.TextureHeightMm"
                />
              </g>

              <g [attr.transform]="getMaterial2TransformString(false, true)">
                <image
                  class="item-material2 mat2-tr"
                  preserveAspectRatio="none"
                  href=""
                  [attr.href]="material2Path"
                  width="0"
                  [attr.width]="item.Material!.TextureWidthMm"
                  height="0"
                  [attr.height]="item.Material!.TextureHeightMm"
                />
              </g>
            </g>
          </pattern>
        </defs>
        <rect
          [attr.fill]="'url(#' + idPostfix + ')'"
          class="item-material2-rect"
          style="stroke: gray; stroke-width: 2px"
          x="0"
          y="0"
          width="0"
          [attr.width]="item.visualWidth"
          height="0"
          [attr.height]="item.Height"
        ></rect>
      </g>

      <g *ngIf="renderMethod === 'material'">
        <image
          class="item-material-only-fallback"
          preserveAspectRatio="xMidYMid slice"
          style="stroke: #000000; stroke-width: 5px; opacity: 0.05"
          href=""
          [attr.href]="item.Material?.ImagePath"
          width="0"
          [attr.width]="item.visualWidth"
          height="0"
          [attr.height]="item.Height"
        />
        <rect
          class="item-material-only-outline"
          style="stroke: gray; stroke-width: 2px; fill: rgba(0, 0, 0, 0.01)"
          width="0"
          [attr.width]="item.visualWidth"
          height="0"
          [attr.height]="item.Height"
        ></rect>
      </g>

      <rect
        class="item-no-img-fallback"
        *ngIf="renderMethod === 'noMaterial'"
        style="stroke: gray; stroke-width: 2px; fill: rgba(0, 0, 0, 0.01)"
        width="0"
        [attr.width]="item.visualWidth"
        height="0"
        [attr.height]="item.Height"
      ></rect>

      <rect
        class="item-no-img-fallback"
        *ngIf="renderMethod === 'outline'"
        style="stroke: #000000; stroke-width: 5px; fill: rgba(0, 0, 0, 0.05)"
        width="0"
        [attr.width]="item.visualWidth"
        height="0"
        [attr.height]="item.Height"
      ></rect>

      <rect
        width="0"
        height="0"
        class="item-mouse-area"
        style="fill: rgba(0, 0, 0, 0)"
        [attr.height]="item.mouseClickOffset.Height"
        [attr.width]="item.mouseClickOffset.Width"
        [attr.x]="item.mouseClickOffset.X"
        [attr.y]="item.mouseClickOffset.Y"
        (mouseup)="mouseUp.emit($event)"
      ></rect>
    </g>
  </g>

  <ng-content></ng-content>

  <rect
    *ngIf="showItem &amp;&amp; showWarnings"
    class="errorOverlay"
    [attr.data-error-level]="item.maxErrorLevel"
    [attr.style]="errorOverlayStyle"
    x="0"
    y="0"
    width="1"
    height="1"
    [attr.width]="item.visualWidth - 2"
    [attr.height]="item.Height - 2"
  ></rect>
</svg:g>
