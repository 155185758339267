import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { BaseVmService } from '@Services/BaseVmService';
import * as Client from '@ClientDto/index';
import * as Enums from '@ClientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { VectorHelper } from '@Util/VectorHelper';
import { Interior2dVm } from '../Interior2dVm';
import { DragInfoService } from '../drag-info.service';
import { ProperSvgElement } from '@Util/ProperSvgElement';
import { ConfigurationItemService } from '@Services/ConfigurationItemService';
import { SwingFlexService } from '@Services/ConfigurationLogic';
import { NotificationService } from '@Services/NotificationService';
import { FloorPlanService } from '@Services/FloorPlanService';
import { SwingFlexSubArea } from '@ClientDto/SwingFlexSubArea';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { ConfigurationItem } from '../../ts/Interface_DTO';
import { ISubscription } from '@Util/Observable';
import { MaterialHelper } from '@Util/MaterialHelper';

@Component({
  selector: 'swing-flex-image',
  templateUrl: './swingFlexImage.svg',
  styleUrls: ['./swingFlexImage.component.scss'],
})
export class SwingFlexImageComponent extends BaseVm implements OnInit {
  @Input()
  public showDoors = false;
  @Input()
  public showRulers = false;
  @Input()
  public showCorpus = true;
  @Input()
  public showTopdown = true;
  @Input()
  public showHinges = true;
  @Input()
  public showCorpusMovable = true;
  @Input()
  public showInterior = false;
  @Input()
  public showBackingRulers = false;
  @Input()
  public cabinetSection!: Client.CabinetSection;
  @Input()
  public selectionObservable!: Subject<Client.SwingFlexArea | null>;
  @Input()
  public selectionSubAreaObservable!: Subject<SwingFlexSubArea | null>;
  @Input()
  public itemSelectionObservable!: Subject<Client.ConfigurationItem | null>;

  private static padding = 30;
  private static doorPadding = 30;
  private readonly initailMinimumDragDist = 6;

  private cache: Partial<{
    corpusItems: Client.ConfigurationItem[];
    corpusJoints: Interior2dVm.Joint[];
    doors: SwingFlexComponent.Door[];
    hinges: SwingFlexComponent.Hinge[];
    gripItems: Client.ConfigurationItem[];
    rulers: Client.Ruler[];
    backingRulers: Client.Ruler[];
    swingItems: Client.ConfigurationItem[];
    backingItems: Client.ConfigurationItem[];
    interiorItems: Client.ConfigurationItem[];
    rulerItems: Client.ConfigurationItem[];
  }> = {};

  private selectedSwingArea: Client.SwingFlexArea | null = null;
  private selectedSwingSubArea: SwingFlexSubArea | null = null;
  private selectedItem: ConfigurationItem | null = null;

  public readonly itemRulerBackgroundWidthWide = 200;
  public readonly itemRulerBackgroundWidthNarrow = 85;
  public readonly itemRulerBackgroundHeight = 70;

  constructor(
    baseVmService: BaseVmService,
    private readonly configurationItemService: ConfigurationItemService,
    private readonly notificationService: NotificationService,
    private readonly floorPlanService: FloorPlanService,
    private readonly swingFlexService: SwingFlexService,
    private readonly dragInfo2: DragInfoService,
  ) {
    super(baseVmService);
  }

  private svgCanvas!: ProperSvgElement;
  private svgMousePoint!: SVGPoint;

  ngOnInit(): void {
    this.svgCanvas = window.document.getElementById('swingFlexCorpus2d') as any;
    this.svgMousePoint = this.svgCanvas.createSVGPoint();

    this.ensureUnsubscribe(
      this.selectionObservable.subscribe(
        (selection) => (this.selectedSwingArea = selection),
      ),
    );
    this.ensureUnsubscribe(
      this.selectionSubAreaObservable.subscribe(
        (selection) => (this.selectedSwingSubArea = selection),
      ),
    );
    this.ensureUnsubscribe(
      this.itemSelectionObservable.subscribe(
        (selection) => (this.selectedItem = selection),
      ),
    );
    this.subscribeTo(this.cabinetSection.cabinet.floorPlan.floorPlan$, (fp) =>
      this.clearCache(),
    );

    this.subscribeTo(this.dragInfo2.dragItem$, (dragInfo) => {
      this.handleDragInfo(dragInfo);
    });
  }

  public get rulers(): Client.Ruler[] {
    if (!this.cache.rulers) {
      this.cache.rulers = Client.Ruler.GetSwingFlexRulers(this.cabinetSection);
    }
    return this.cache.rulers;
  }

  public get backingRulers(): Client.Ruler[] {
    if (!this.cache.backingRulers) {
      this.cache.backingRulers = [];

      const backingItemRulerPosY = -(Constants.rulerWidth * 2) / 3;

      for (let backingItem of this.backingItems) {
        let ruler = new Client.Ruler(
          false,
          { X: backingItem.X, Y: backingItemRulerPosY },
          backingItem.Width,
          this.cabinetSection!.Height,
          false,
        );
        this.cache.backingRulers.push(ruler);
      }
    }
    return this.cache.backingRulers;
  }

  public getSubAreaOffsetY(subArea: SwingFlexSubArea): number {
    let spaceForCorpusOrFeet = this.cabinetSection.corpus.heightBottom;
    if (spaceForCorpusOrFeet <= 0)
      spaceForCorpusOrFeet =
        this.cabinetSection.swingFlex.productLineProperties
          .SwingFlexBottomOffset;
    const plinthHeight = subArea.parentArea.hasPlinth
      ? this.cabinetSection.swingFlex.getPlinthHeight()
      : 0;
    const bottomShelfHeight =
      this.cabinetSection.swingFlex.getCorpusShelfHeight();
    return (
      this.cabinetSection.Height -
      (spaceForCorpusOrFeet + plinthHeight + bottomShelfHeight)
    );
  }

  public get swingAreas() {
    return this.cabinetSection.swingFlex.areas;
  }

  public get swingSubAreas() {
    return this.cabinetSection.swingFlex.areas.flatMap((a) => a.subAreas);
  }

  public get corpusItems(): Client.ConfigurationItem[] {
    if (!this.cache.corpusItems) {
      this.cache.corpusItems = this.cabinetSection.corpus.allCorpusItems;
    }
    return this.cache.corpusItems;
  }

  public get corpusJoints(): Interior2dVm.Joint[] {
    if (!this.cache.corpusJoints) {
      let topJoints = this.cabinetSection.corpus.jointsTop.map((j) => {
        let height = Math.max(
          0,
          ...this.cabinetSection.corpus.itemsTop.map((i) => i.Height),
        );
        return <Interior2dVm.Joint>{
          X: j + this.cabinetSection.corpus.topDeductionLeft,
          Y: this.cabinetSection.Height - height,
          height: height,
        };
      });
      let bottomJoints = this.cabinetSection.corpus.jointsBottom.map((j) => {
        let height = Math.max(
          0,
          ...this.cabinetSection.corpus.itemsBottom.map((i) => i.Height),
        );
        return <Interior2dVm.Joint>{
          X: j + this.cabinetSection.corpus.bottomDeductionLeft,
          Y: 0,
          height: height,
        };
      });
      this.cache.corpusJoints = topJoints.concat(...bottomJoints);
    }
    return this.cache.corpusJoints;
  }

  public get doors(): SwingFlexComponent.Door[] {
    if (!this.cache.doors) {
      this.cache.doors = SwingFlexImageComponent.createDoors(this.swingAreas);
    }
    return this.cache.doors;
  }

  public get hinges() {
    if (!this.cache.hinges) {
      this.cache.hinges = SwingFlexImageComponent.createHinges(
        this.cabinetSection,
      );
    }
    return this.cache.hinges;
  }

  public get gripItems() {
    if (!this.cache.gripItems) {
      this.cache.gripItems = this.cabinetSection.swingFlex.areas.flatMap(
        (a) => a.gripItems,
      );
    }
    return this.cache.gripItems;
  }

  public get swingItems(): Client.ConfigurationItem[] {
    if (!this.cache.swingItems) {
      this.cache.swingItems = Enumerable.from(this.swingAreas)
        .selectMany((area) =>
          area.items
            .filter(
              (item) =>
                item.ItemType !== Interface_Enums.ItemType.SwingFlexBacking,
            )
            .concat(area.areaSplitItems),
        )
        .orderBy((item) => item.frontZ)
        .toArray();
    }
    return this.cache.swingItems;
  }

  public get rulerItems(): Client.ConfigurationItem[] {
    if (this.cache.rulerItems === undefined) {
      this.cache.rulerItems = this.swingItems.concat(this.backingItems);
    }
    return this.cache.rulerItems;
  }

  public get backingItems(): Client.ConfigurationItem[] {
    if (!this.cache.backingItems) {
      this.cache.backingItems = Enumerable.from(this.swingAreas)
        .selectMany((area) => area.items)
        .where(
          (item) => item.ItemType === Interface_Enums.ItemType.SwingFlexBacking,
        )
        .orderBy((item) => item.frontZ)
        .toArray();
    }
    return this.cache.backingItems;
  }

  public get interiorItems(): Client.ConfigurationItem[] {
    if (!this.cache.interiorItems) {
      this.cache.interiorItems = Enumerable.from(
        this.cabinetSection.interior.items,
      )
        .orderBy((item) => (item.isGable ? 0 : 1))
        .thenBy((item) => item.frontZ)
        .toArray();
    }
    return this.cache.interiorItems;
  }

  public get viewBoxPosition(): Interface_DTO_Draw.Vec2d {
    let result = {
      X: -SwingFlexImageComponent.padding,
      Y: -SwingFlexImageComponent.padding,
    };

    result.X -= this.rulerSpacing.X;
    result.Y -= this.rulerSpacing.Y;

    return result;
  }

  private get rulerSpacing(): Interface_DTO_Draw.Vec2d {
    return this.showRulers
      ? { X: 2 * Constants.rulerWidth, Y: 3 * Constants.rulerWidth }
      : { X: 0, Y: 0 };
  }

  private get doorSpacing(): Interface_DTO_Draw.Vec2d {
    return !this.showTopdown
      ? { X: 0, Y: 0 }
      : {
          X: 0,
          Y:
            Math.max(0, ...this.doors.map((d) => d.pos2.Y)) +
            SwingFlexImageComponent.doorPadding,
        };
  }

  private get backingRulerSpacing(): Interface_DTO_Draw.Vec2d {
    return this.showBackingRulers
      ? { X: 0, Y: Constants.rulerWidth }
      : { X: 0, Y: 0 };
  }

  public get viewBoxSize(): Interface_DTO_Draw.Vec2d {
    let result = {
      X: this.cabinetSection.Width + 2 * SwingFlexImageComponent.padding,
      Y: this.cabinetSection.Height + 2 * SwingFlexImageComponent.padding,
    };

    result = VectorHelper.add(
      result,
      this.rulerSpacing,
      this.doorSpacing,
      this.backingRulerSpacing,
    );

    return result;
  }

  public get viewBoxString(): string {
    let pos = this.viewBoxPosition;
    let size = this.viewBoxSize;
    return pos.X + ' ' + pos.Y + ' ' + size.X + ' ' + size.Y;
  }

  public get dragInfo():
    | Interior2dVm.ItemDragInfo
    | Interior2dVm.RectangleSelectInfo
    | Interior2dVm.NeighborItemDragPrepareInfo
    | Interior2dVm.NeighborItemDragInfo
    | undefined {
    return this.dragInfo2.dragInfo;
  }

  public set dragInfo(
    value:
      | Interior2dVm.ItemDragInfo
      | Interior2dVm.RectangleSelectInfo
      | Interior2dVm.NeighborItemDragPrepareInfo
      | Interior2dVm.NeighborItemDragInfo
      | undefined,
  ) {
    this.dragInfo2.dragInfo = value;
  }

  private clearCache() {
    this.cache = {};
  }

  public selectSwingArea(swingArea: Client.SwingFlexArea | null) {
    this.selectionObservable.next(swingArea);
  }

  public selectSwingSubArea(swingSubArea: SwingFlexSubArea | null) {
    this.selectionSubAreaObservable.next(swingSubArea);
    if (!!swingSubArea) {
      let area = this.swingAreas.find((a) => a.subAreas.includes(swingSubArea));
      this.selectSwingArea(area || null);
    } else {
      this.selectSwingArea(null);
    }
  }

  public isSwingAreaSelected(swingArea: Client.SwingFlexArea): boolean {
    return this.selectedSwingArea === swingArea;
  }

  public isItemSelected(item: ConfigurationItem): boolean {
    return this.selectedItem === item;
  }

  public isSwingSubAreaSelected(swingSubArea: SwingFlexSubArea): boolean {
    return this.selectedSwingSubArea === swingSubArea;
  }

  public static getCabinetSectionRulers(
    cabinetSection: Client.CabinetSection,
  ): Client.Ruler[] {
    return [
      new Client.Ruler(
        false,
        {
          X: 0,
          Y: (Constants.rulerWidth * 5) / 2,
        },
        cabinetSection.Width,
        0,
        false,
      ),
    ];
  }

  public static getSwingRulers(
    cabinetSection: Client.CabinetSection,
  ): Client.Ruler[] {
    let areaItems = Enumerable.from(cabinetSection.swingFlex.areas)
      .selectMany((area) => area.items)
      .toArray();
    let minX = Math.min(0, ...areaItems.map((item) => item.X));
    let maxX =
      areaItems.length > 0
        ? Math.max(...areaItems.map((item) => item.rightX))
        : cabinetSection.Width;

    let rulers = [
      // Current actual width
      new Client.Ruler(
        false,
        {
          X: minX,
          Y: (Constants.rulerWidth * 3) / 2,
        },
        maxX - minX,
        0,
        false,
      ),

      // Remaining width
      new Client.Ruler(
        false,
        {
          X: maxX,
          Y: (Constants.rulerWidth * 3) / 2,
        },
        cabinetSection.Width - maxX,
        0,
        false,
      ),

      // With of swingFlexArea insides
      ...cabinetSection.swingFlex.areas.map(
        (area) =>
          new Client.Ruler(
            false,
            {
              X: area.insideRect.X,
              Y: (Constants.rulerWidth * 1) / 2,
            },
            area.insideRect.Width,
            0,
            false,
          ),
      ),

      // Vertical rulers
      // CabinetSection height
      new Client.Ruler(
        true,
        {
          X: (-Constants.rulerWidth * 3) / 2,
          Y: 0,
        },
        cabinetSection.Height,
        cabinetSection.Height,
        false,
      ),
    ];

    let firstArea = cabinetSection.swingFlex.areas[0];
    if (firstArea) {
      rulers.push(
        new Client.Ruler(
          true,
          {
            X: (-Constants.rulerWidth * 1) / 2,
            Y: firstArea.insideRect.Y,
          },
          firstArea.insideRect.Height,
          cabinetSection.Height,
          false,
        ),
      );
    }

    // Area corpus gable widths
    cabinetSection.swingFlex.areas.forEach((area) => {
      Enumerable.from(area.items)
        .where((item) => item.isSwingCorpusGable)
        .forEach((gable) =>
          rulers.push(
            new Client.Ruler(
              false,
              {
                X: gable.X,
                Y: (Constants.rulerWidth * 1) / 2,
              },
              gable.Width,
              0,
              false,
            ),
          ),
        );
    });

    return rulers;
  }

  public shouldDisplayRulerFor(item: Client.ConfigurationItem): boolean {
    if (!this.showRulers) {
      return false;
    } else if (item.ItemType === Interface_Enums.ItemType.SwingFlexDoor) {
      return this.showDoors;
    } else if (
      item.ItemType === Interface_Enums.ItemType.SwingFlexCorpusMovable
    ) {
      return this.showCorpusMovable;
    } else if (item.ItemType === Interface_Enums.ItemType.SwingFlexBacking) {
      return !this.showDoors && !this.showCorpusMovable;
    }

    return this.isItemDisplayed(item);
  }

  public isItemDisplayed(item: Client.ConfigurationItem): boolean {
    if (item.ItemType === Interface_Enums.ItemType.SwingFlexDoor) {
      return this.showDoors;
    } else if (
      item.ItemType === Interface_Enums.ItemType.SwingFlexCorpusMovable
    ) {
      return this.showCorpusMovable;
    } else if (item.ItemType === Interface_Enums.ItemType.Interior) {
      return this.showInterior;
    }
    return true;
  }

  public isItemMoveable(item: Client.ConfigurationItem): boolean {
    return item.ItemType === Interface_Enums.ItemType.SwingFlexCorpusMovable;
  }

  public getMirrorTransformString(item: Client.ConfigurationItem) {
    if (item.isMirrored) {
      return ' translate( ' + item.Width + ' ) scale( -1 1 ) ';
    } else {
      return '';
    }
  }

  public getDragOverlayTransformString(item: Client.ConfigurationItem): string {
    let result = '';
    let x = item.X;
    let y = this.cabinetSection.Height - item.topY;
    result += ' translate( ' + x + ' , ' + y + ' ) ';
    return result;
  }

  public getHingeTransformString(hinge: SwingFlexComponent.Hinge): string {
    let y = this.cabinetSection.Height - (2 * hinge.y + hinge.height);
    return ' translate( 0 , ' + y + ' ) ';
  }

  public getItemRulerString(item: Client.ConfigurationItem): string {
    let result = '' + item.PositionNumber;

    const addSnapPosition =
      item.ItemType !== Interface_Enums.ItemType.SwingFlexDoor &&
      item.ItemType !== Interface_Enums.ItemType.SwingFlexBacking &&
      item.ItemType !== Interface_Enums.ItemType.SwingFlexCorpus;

    if (addSnapPosition) {
      result += ' - ' + item.snappedHoleY.toFixed(0);
    }

    return result;
  }

  public getItemRulerBackgroundWidth(item: Client.ConfigurationItem): number {
    const useWide =
      item.ItemType !== Interface_Enums.ItemType.SwingFlexDoor &&
      item.ItemType !== Interface_Enums.ItemType.SwingFlexBacking &&
      item.ItemType !== Interface_Enums.ItemType.SwingFlexCorpus;

    return useWide
      ? this.itemRulerBackgroundWidthWide
      : this.itemRulerBackgroundWidthNarrow;
  }

  public getSelectBoxOffsetY(item: Client.ConfigurationItem): number {
    let offsetY = item.isShelf_19_22 ? -20 : 20;
    return offsetY;
  }

  public getSelectBoxHeight(item: Client.ConfigurationItem): number {
    let height = item.isShelf_19_22 ? item.Height + 40 : item.Height - 40;
    return height;
  }

  public static createDoors(
    areas: Client.SwingFlexArea[],
  ): SwingFlexComponent.Door[] {
    let doors: SwingFlexComponent.Door[] = [];
    for (let area of areas) {
      // No hinges => no doors...
      if (area.hinges.length <= 0) continue;

      switch (area.hingeSide) {
        case Enums.HingeSide.Left:
          doors.push(
            new SwingFlexComponent.Door(
              area.insideRect.X,
              area.insideRect.Width,
              area.hingeSide,
            ),
          );
          break;
        case Enums.HingeSide.Right:
          doors.push(
            new SwingFlexComponent.Door(
              area.insideRect.topRight.X,
              area.insideRect.Width,
              area.hingeSide,
            ),
          );
          break;
        case Enums.HingeSide.Both:
          doors.push(
            new SwingFlexComponent.Door(
              area.insideRect.X,
              area.insideRect.Width / 2,
              Enums.HingeSide.Left,
            ),
          );
          doors.push(
            new SwingFlexComponent.Door(
              area.insideRect.topRight.X,
              area.insideRect.Width / 2,
              Enums.HingeSide.Right,
            ),
          );
          break;
      }
    }
    return doors;
  }

  public static createHinges(
    cabinetSection: Client.CabinetSection,
  ): SwingFlexComponent.Hinge[] {
    let hingesItems = Enumerable.from(
      cabinetSection.swingFlex.areas,
    ).selectMany((area) => area.hinges);

    let hinges: SwingFlexComponent.Hinge[] = [];

    for (let hingeData of hingesItems) {
      hinges.push(
        new SwingFlexComponent.Hinge(
          hingeData.hingeType,
          hingeData.centerX,
          hingeData.centerY + hingeData.doorOffset,
        ),
      );
    }

    return hinges;
  }

  // Drag and drop
  //#region

  public mouseMove(evt: MouseEvent | TouchEvent) {
    if (!this.dragInfo) {
      let dragData = this.dragInfo2.value;
      if (dragData) {
        let item: Client.ConfigurationItem;

        if (dragData.item) {
          item = this.configurationItemService.createItemFromOtherItem(
            dragData.item,
            this.cabinetSection,
          );
        } else {
          item = this.configurationItemService.createConfigurationItem(
            Interface_Enums.ItemType.SwingFlexCorpusMovable,
            dragData.productId,
            dragData.materialId || null,
            this.cabinetSection,
          );
          if (!item) return;

          if (!item.Material) {
            // Drawers use door material, others use corpus material
            let materialId: number | null = item.isPullout
              ? (this.cabinetSection.swingFlex?.doorMaterial?.Id ?? null)
              : (this.cabinetSection.swingFlex?.corpusMaterial?.Id ?? null);

            if (!!materialId) item.MaterialId = materialId;
          }

          if (item.canHaveGrip) {
            let gripProduct = this.cabinetSection!.swingFlex.drawerGrip;
            let gripMaterial =
              this.cabinetSection!.swingFlex.drawerGripMaterial ??
              MaterialHelper.getDefaultMaterial(gripProduct?.materials);
            item.gripProduct = gripProduct;
            item.gripMaterial = gripMaterial;
          }
        }

        let pos = this.getDomainPosition(evt);
        item.X = 0;
        item.Y = 0;

        let snapService = this.swingFlexService.getSnapInfoService(
          this.cabinetSection,
          [item],
          item,
        );

        let dragInfo: Interior2dVm.ItemDragInfo = {
          type: 'ItemDragInfo',
          mouseStartPos: pos,
          offset: { X: item.X / 2, Y: item.Y / 2 },
          snapInfoService: snapService,
          snapInfo: snapService.getSnapInfo(pos),
          dragItems: [item],
          dragStarted: false,
        };
        this.dragInfo = dragInfo;

        this.itemSelectionObservable.next(item);
      }
    }

    if (!this.dragInfo) {
      return;
    }
    evt.preventDefault(); //prevent browser from selecting text etc
    let mousePos = this.getDomainPosition(evt);

    if (this.dragInfo.type === 'ItemDragInfo') {
      if (!this.dragInfo.dragStarted) {
        let currentPos = this.getPos(evt);
        let dist = VectorHelper.dist(currentPos, this.dragInfo.mouseStartPos);
        this.dragInfo.dragStarted = dist >= this.initailMinimumDragDist;
      }

      if (this.dragInfo.dragStarted) {
        this.dragInfo.snapInfo =
          this.dragInfo.snapInfoService.getSnapInfo(mousePos);
      }
    }
  }

  public mouseUp(evt: MouseEvent | TouchEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.dragInfo2.value = undefined;

    if (!this.dragInfo) {
      //user did a mouseDown somewhere else and a mouseUp here - do nothing
    } else if (this.dragInfo.type === 'ItemDragInfo') {
      //user dragged items around
      if (!this.dragInfo.dragStarted) {
        this.dragInfo = undefined;
      } else {
        let placeInfo = this.dragInfo.snapInfoService.place();
        this.notificationService.displayRecalulationMessages(
          placeInfo.recalculationMessages,
        );
        this.setChanged();
      }
    }

    this.dragInfo = undefined;
  }

  public backgroundMouseDown(evt: MouseEvent | TouchEvent) {
    if (this.dragInfo) {
      //user is already dragging something
      return;
    }

    this.itemSelectionObservable.next(null);

    let pos = this.getDomainPosition(evt);
    this.dragInfo = {
      type: 'RectangleSelectInfo',
      isStarted: false,
      mouseStartPos: pos,
      topLeft: pos,
      bottomRight: pos,
      size: { X: 0, Y: 0 },
    };
    evt.preventDefault();
  }

  public itemMouseDown(
    evt: MouseEvent | TouchEvent,
    item: Client.ConfigurationItem,
  ) {
    if (!this.isItemMoveable(item)) {
      return;
    }

    if (this.dragInfo) {
      //user is already dragging something
      return;
    }

    this.selectSwingSubArea(null);
    this.itemSelectionObservable.next(item);

    let domainPos = this.getDomainPosition(evt);
    let snapInfoService = this.swingFlexService.getSnapInfoService(
      this.cabinetSection,
      [item],
      domainPos,
    );
    let snapInfo = snapInfoService.getSnapInfo(domainPos);
    let dragInfo: Interior2dVm.ItemDragInfo = {
      type: 'ItemDragInfo',
      mouseStartPos: this.getPos(evt),
      offset: this.getDomainPosition(evt),
      dragItems: [item],
      dragStarted: false,
      snapInfoService: snapInfoService,
      snapInfo: snapInfo,
    };
    this.dragInfo = dragInfo;

    evt.preventDefault();
    evt.stopPropagation();
  }

  private async setChanged() {
    await this.floorPlanService.setChanged(
      this.cabinetSection.cabinet.floorPlan,
    );
  }

  private getDomainPosition(
    evt: MouseEvent | TouchEvent,
  ): Interface_DTO_Draw.Vec2d {
    let clientX: number;
    let clientY: number;
    if (evt instanceof MouseEvent) {
      clientX = evt.clientX;
      clientY = evt.clientY;
    } else if (evt instanceof TouchEvent) {
      clientX = evt.changedTouches[0].clientX;
      clientY = evt.changedTouches[0].clientY;
    } else {
      throw new Error('unknown event type');
    }

    this.svgMousePoint.x = clientX;
    this.svgMousePoint.y = clientY;
    let pt = this.svgMousePoint.matrixTransform(
      this.svgCanvas.getScreenCTM().inverse(),
    );
    let result = {
      X: pt.x,
      Y: this.cabinetSection.Height - pt.y,
    };

    return result;
  }

  private getPos(event: MouseEvent | TouchEvent): Interface_DTO_Draw.Vec2d {
    if (event instanceof MouseEvent) {
      return {
        X: event.clientX,
        Y: event.clientY,
      };
    } else {
      return {
        X: event.changedTouches[0].clientX,
        Y: event.changedTouches[0].clientY,
      };
    }
  }

  //#endregion

  // Drag and drop, touch
  //#region

  private handleDragInfo(dragInfo?: Client.ProductDragInfo) {
    if (
      dragInfo &&
      dragInfo.touchEventObservable &&
      dragInfo.touchEventObservable.value
    ) {
      //touch event is starting
      let lastEvent: TouchEvent | undefined;
      //this.mouseMove(dragInfo.touchEventObservable.value);
      this.touchEventSubscription = dragInfo.touchEventObservable.observe(
        (touchEvent) => {
          if (!touchEvent) {
            this.touchEventSubscription = undefined;
            if (lastEvent) this.mouseUp(lastEvent);
          } else {
            let touchPos = this.getDomainPosition(touchEvent);
            if (touchPos.X > 0 && touchPos.X < this.cabinetSection.Width) {
              //quick check to see if touch is inside canvas bounds
              lastEvent = touchEvent;
              this.mouseMove(touchEvent);
            }
          }
        },
      );
    } else {
      //touch drag event is ending
      this.touchEventSubscription = undefined;
    }
  }

  private _touchEventSubscription: ISubscription | undefined;
  private set touchEventSubscription(val: ISubscription | undefined) {
    if (this._touchEventSubscription) {
      this._touchEventSubscription.dispose();
    }
    this._touchEventSubscription = val;
  }

  //#endregion

  public override dispose() {
    super.dispose();
    this.touchEventSubscription = undefined;
  }
}

export module SwingFlexComponent {
  export class Door {
    private static readonly doorRotationDegrees = 25;
    public readonly pos1: Readonly<Interface_DTO_Draw.Vec2d>;
    public readonly pos2: Readonly<Interface_DTO_Draw.Vec2d>;
    constructor(
      public readonly hingeXPosition: number,
      public readonly width: number,
      public readonly hingeSide: Enums.HingeSide,
    ) {
      let rotation =
        hingeSide === Enums.HingeSide.Left
          ? Door.doorRotationDegrees
          : 180 - Door.doorRotationDegrees;
      this.pos1 = { X: hingeXPosition, Y: 0 };
      this.pos2 = {
        X: hingeXPosition + width * Math.cos((rotation / 180) * Math.PI),
        Y: width * Math.sin((rotation / 180) * Math.PI),
      };
    }
  }

  export class Hinge {
    public readonly height: number = 50;
    public readonly width: number = 80;
    public readonly style: string;

    constructor(
      private hingeType: Enums.SwingFlexHingeType,
      private centerX: number,
      private centerY: number,
    ) {
      const colorString =
        hingeType === Enums.SwingFlexHingeType.Special
          ? 'fill: rgba(255,255,0,0.75);'
          : 'fill: rgba(0,255,0,0.75);';

      this.style = 'stroke:gray; stroke-width:2px; ' + colorString;
    }

    public get x(): number {
      return this.centerX - this.width / 2;
    }

    public get y(): number {
      return this.centerY - this.height / 2;
    }

    public get text(): string {
      return this.hingeType === Enums.SwingFlexHingeType.Special
        ? '155'
        : '110';
    }
  }
}
