import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { CabinetSection } from 'app/ts/clientDto/index';

import { CameraAngle } from 'app/ts/clientDto/CameraAngle';
import { Door } from 'app/ts/clientDto/Door';
import { Material } from 'app/ts/clientDto/Material';
import { FloorPlanHelper } from 'app/ts/util/FloorPlanHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { CabinetValidationService } from 'app/ts/services/Validation/CabinetValidationService';
import { CabinetProperties } from '../properties/CabinetProperties';
import { ProductLineProperties } from '../Interface_DTO_ProductLineProperties';

export class Cabinet {
  constructor(
    public readonly floorPlan: Client.FloorPlan,
    private dto: Interface_DTO.Cabinet,
  ) {
    this.cabinetSections = dto.CabinetSections.map(
      (dtoCs) => new Client.CabinetSection(dtoCs, this),
    );
    for (let section of this.cabinetSections) {
      section.loadFromDto();
    }
  }

  private _cache: {
    floorPlanPosition?: Interface_DTO_Draw.Vec3d;
    wall?: Interface_DTO_FloorPlan.WallSection;
    item?: Interface_DTO_FloorPlan.Item;
    errorInfos?: Client.ErrorInfo[];
    productLine?: Client.ProductLine;
    maxErrorLevel?: Interface_Enums.ErrorLevel;
    actualCabinetSections?: Client.CabinetSection[];
  } = {};

  private _properties?: CabinetProperties;

  public cabinetSections: Client.CabinetSection[] = [];
  public cameraAngle?: CameraAngle;

  public get editorAssets(): Client.EditorAssets {
    return this.floorPlan.editorAssets;
  }

  public get backwallSection(): CabinetSection {
    let i: number;
    let quarterTurns = (this.Rotation % 360) / 90;
    switch (this.CabinetType) {
      case Interface_Enums.CabinetType.CornerCabinet:
        i = quarterTurns % 2;
        break;
      case Interface_Enums.CabinetType.WalkIn:
        i = quarterTurns === 3 ? 0 : quarterTurns;
        break;
      default:
        i = 0;
    }
    return this.cabinetSections[i];
  }
  public getSectionByIndex(index: number): Client.CabinetSection | undefined {
    if (index < 1) return undefined;
    let result: Client.CabinetSection | undefined = undefined;
    for (let section of this.cabinetSections) {
      if (section.CabinetSectionIndex === index) {
        result = section;
        break;
      }
    }

    if (!result) return undefined;
    if (result.CabinetType === Interface_Enums.CabinetType.SharedItems)
      return undefined;
    if (result.CabinetType === Interface_Enums.CabinetType.None)
      return undefined;
    return result;
  }

  public getDtoObject() {
    this.dto.CabinetSections = this.cabinetSections.map((ccs) => ccs.saveTo());
    this.dto.PropertiesJson = this._properties
      ? JSON.stringify(this._properties)
      : null;
    return this.dto;
  }

  private _isDirty = false;
  public get isDirty() {
    return this._isDirty;
  }
  public set isDirty(b: boolean) {
    this._isDirty = b;
    if (b) {
      this.clearCachedValues();
      this.floorPlan.isDirty = true;
    }
  }

  private clearCachedValues() {
    this._cache = {};
  }

  public clearAllSectionCaches() {
    this.actualCabinetSections.forEach((cs) => cs.clearBackingVariables());
  }

  public restoreFrom(dtoCabinet: Interface_DTO.Cabinet) {
    this._properties = undefined;
    this.dto = dtoCabinet;

    let activeSections: Client.CabinetSection[] = [];

    for (let dtoSection of this.dto.CabinetSections) {
      let oldSec: Client.CabinetSection | null = null;
      for (let sec of this.cabinetSections) {
        if (sec.CabinetSectionIndex === dtoSection.CabinetSectionIndex) {
          oldSec = sec;
          break;
        }
      }
      if (!oldSec) {
        oldSec = new Client.CabinetSection(dtoSection, this);
      }
      oldSec.loadFrom(dtoSection);
      activeSections.push(oldSec);
    }
    this.cabinetSections = activeSections;
    //this.cabinetSections = this.cabinetSections.sort((a, b) => a.CabinetSectionIndex - b.CabinetSectionIndex);
  }

  //#region PropertiesJson
  private get properties(): CabinetProperties {
    if (!this._properties) {
      const propertiesJson = this.dto.PropertiesJson ?? '{}';
      this._properties = {
        ...CabinetProperties.defaultValue,
        ...(JSON.parse(propertiesJson) as Partial<CabinetProperties>),
      };
    }
    return this._properties;
  }

  //#endregion PropertiesJson

  //#region DTO mappings

  get CabinetIndex() {
    return this.dto.CabinetIndex;
  }
  set CabinetIndex(val: number) {
    if (val === this.dto.CabinetIndex) return;
    this.dto.CabinetIndex = val;
    this.isDirty = true;
  }

  get CabinetType() {
    return this.dto.CabinetType;
  }
  set CabinetType(val: Interface_Enums.CabinetType) {
    if (val === this.dto.CabinetType) return;
    this.dto.CabinetType = val;
    this.isDirty = true;
  }

  get CreatedDate() {
    return this.dto.CreatedDate;
  }
  set CreatedDate(val: string) {
    if (val === this.dto.CreatedDate) return;
    this.dto.CreatedDate = val;
    this.isDirty = true;
  }

  get Description() {
    return this.dto.Description;
  }
  set Description(val: string) {
    if (val === this.dto.Description) return;
    this.dto.Description = val;
    this.isDirty = true;
  }

  get FloorPlanId() {
    return this.dto.FloorPlanId;
  }
  set FloorPlanId(val: number | null) {
    if (val === this.dto.FloorPlanId) return;
    this.dto.FloorPlanId = val;
    this.isDirty = true;
  }

  get Rotation() {
    return this.dto.Rotation;
  }
  set Rotation(val: number) {
    if (val === this.dto.Rotation) return;
    this.dto.Rotation = val;
    this.isDirty = true;
  }

  get Name() {
    return this.dto.Name;
  }
  set Name(val: string) {
    if (val === this.dto.Name) return;
    this.dto.Name = val;
    this.isDirty = true;
  }

  get OverrideChain() {
    return this.dto.OverrideChain;
  }
  set OverrideChain(val: boolean) {
    if (val === this.dto.OverrideChain) return;
    this.dto.OverrideChain = val;
    this.isDirty = true;
  }

  get ProductLineId() {
    return this.dto.ProductLineId;
  }
  set ProductLineId(val: Interface_Enums.ProductLineId) {
    if (val === this.dto.ProductLineId) return;
    this.dto.ProductLineId = val;
    this.isDirty = true;
  }

  // // get productLine(): Client.ProductLine {
  // //   return this.editorAssets.productLines.find(pl => pl.Id === this.ProductLineId);
  // // }

  // get productLineProperties(): ProductLineProperties {
  //   if (this._cache.productLineProperties === undefined) {
  //     this._cache.productLineProperties = this.productLine.Properties;
  //   }
  //   return this._cache.productLineProperties;
  // }

  get SupporterId() {
    return this.dto.SupporterId;
  }
  set SupporterId(val: number | null) {
    if (val === this.dto.SupporterId) return;
    this.dto.SupporterId = val;
    this.isDirty = true;
  }

  get UserId() {
    return this.dto.UserId;
  }
  set UserId(val: number) {
    if (val === this.dto.UserId) return;
    this.dto.UserId = val;
    this.isDirty = true;
  }

  get CabinetSections() {
    return this.dto.CabinetSections;
  }
  set CabinetSections(val: Interface_DTO.CabinetSection[]) {
    if (val === this.dto.CabinetSections) return;
    this.dto.CabinetSections = val;
    this.isDirty = true;
  }

  get Alignment() {
    return this.dto.Alignment;
  }
  set Alignment(val: Interface_Enums.CabinetAlignment) {
    if (this.dto.Alignment === val) return;
    this.dto.Alignment = val;
    this.isDirty = true;
  }

  get UnevenFloor() {
    return this.dto.UnevenFloor;
  }
  set UnevenFloor(val: boolean | null) {
    if (val === this.dto.UnevenFloor) return;
    this.dto.UnevenFloor = val;
    this.isDirty = true;
  }

  get UnevenLeftWall() {
    return this.dto.UnevenLeftWall;
  }
  set UnevenLeftWall(val: boolean | null) {
    if (val === this.dto.UnevenLeftWall) return;
    this.dto.UnevenLeftWall = val;
    this.isDirty = true;
  }

  get UnevenRightWall() {
    return this.dto.UnevenRightWall;
  }
  set UnevenRightWall(val: boolean | null) {
    if (val === this.dto.UnevenRightWall) return;
    this.dto.UnevenRightWall = val;
    this.isDirty = true;
  }

  get UseRoof() {
    return this.dto.UseRoof;
  }
  set UseRoof(val: boolean | null) {
    if (val === this.dto.UseRoof) return;
    this.dto.UseRoof = val;
    this.isDirty = true;
  }

  //#endregion DTO mappings

  //#region Cabinet wide section settings

  /**
   * Set height on all sections, with the same type as the "donor"
   * @param donor
   * @param newHeight
   */
  public setHeight(donor: Client.CabinetSection, newHeight: number) {
    for (let section of this.cabinetSections) {
      if (
        section.CabinetSectionIndex !== donor.CabinetSectionIndex &&
        section.CabinetType !== donor.CabinetType
      ) {
        continue;
      }

      section.setHeight(newHeight);
    }
  }

  //#endregion Cabinet wide section settings

  //#region Cabinet wide corpus settings

  /**
   * Set corpus material id on all sections, with the same type as the "donor"
   * @param donor
   * @param materialId
   */
  public setCorpusMaterial(
    donor: Client.CabinetSection,
    material: Material | null,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.CorpusMaterialId = material != null ? material.Id : null;
      section.CorpusMaterialNumber = material != null ? material.Number : '';
    }
  }

  /**
   * Set corpus panel top id on all sections, with the same type as the "donor"
   * @param donor
   * @param newId
   */
  public setCorpusPanelTopId(donor: Client.CabinetSection, newId: number) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.corpus.CorpusPanelTopId = newId;
    }
  }

  /**
   * Set corpus panel top height on all sections, with the same type as the "donor"
   * @param donor
   * @param newHeight
   */
  public setCorpusPanelHeightTop(
    donor: Client.CabinetSection,
    newHeight: number,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.corpus.setHeightTop(newHeight);
    }
  }

  /**
   * Set corpus panel bottom id on all sections, with the same type as the "donor"
   * @param donor
   * @param newId
   */
  public setCorpusPanelBottomId(donor: Client.CabinetSection, newId: number) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.corpus.CorpusPanelBottomId = newId;
    }
  }

  /**
   * Set corpus panel bottom height on all sections, with the same type as the "donor"
   * @param donor
   * @param newHeight
   */
  public setCorpusPanelHeightBottom(
    donor: Client.CabinetSection,
    newHeight: number,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.corpus.setHeightBottom(newHeight);
    }
  }

  //#endregion Cabinet wide corpus settings

  //#region Cabinet wide interior settings

  /**
   * Set interior material id on all sections, with the same type as the "donor"
   * @param donor
   * @param materialId
   */
  public setInteriorMaterial(
    donor: Client.CabinetSection,
    material: Material | null,
    applyToItems: boolean,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.InteriorMaterialId = material !== null ? material.Id : null;
      section.InteriorMaterialNumber = material != null ? material.Number : '';

      if (applyToItems && !!material) {
        for (let item of section.interior.items) {
          if (item.IsLocked) continue;

          if (
            item.Product &&
            item.Product.materials.some((mat) => {
              if (mat.Id !== material.Id) return false;

              if (
                mat.isOverride &&
                !this.floorPlan.FullCatalogAllowOtherMaterials
              )
                return false;

              return true;
            })
          ) {
            //product is available in the selected material - set item material
            item.MaterialId = material.Id;
          }
        }
      }
    }
  }

  //#endregion Cabinet wide interior settings

  //#region Cabinet wide door settings

  /**
   * Set door on all sections with the same type as the "donor"
   * @param donor
   * @param newProfile
   */
  public setDoorProfile(
    donor: Client.CabinetSection,
    newProfile: Client.Product | null,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.doors.setProfile(newProfile);
      //if (section.CabinetType === Interface_Enums.CabinetType.Doors
      //    && section.editorAssets.salesChainSettings[Interface_Enums.SalesChainSettingKey.ForceOnlyDoors] === "1"
      //) {
      //    section.doors.onlyDoor = true;
      //}
    }
  }

  /**
   * Set door material on all sections with the same type as the "donor"
   * @param donor
   * @param newMaterial
   */
  public setDoorMaterial(
    donor: Client.CabinetSection,
    newMaterial: Client.Material | null,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.doors.setProfileMaterial(newMaterial);
    }
  }

  /**
   * Set railset on all sections with the same type as the "donor"
   * @param donor
   * @param newRailSet
   */
  public setDoorRailset(
    donor: Client.CabinetSection,
    newRailSet: Client.RailSet | null,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.doors.setRailSet(newRailSet);
    }
  }

  /**
   * Set top railset material on all sections with the same type as the "donor"
   * @param donor
   * @param newMaterial
   */
  public setRailMaterialTop(
    donor: Client.CabinetSection,
    newMaterial: Client.Material | null,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.doors.setRailMaterialTop(newMaterial);
    }
  }

  /**
   * Set bottom railset material on all sections to the same as on the "donor"
   * @param donor
   * @param newMaterial
   */
  public setRailMaterialBottom(
    donor: Client.CabinetSection,
    newMaterial: Client.Material | null,
  ) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.doors.setRailMaterialBottom(newMaterial);
    }
  }

  /**
   * Set grip on all sections to the same as on the "donor"
   * @param donor
   * @param newGrip
   */
  public setGrip(donor: Client.CabinetSection, newGrip: Client.Product | null) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.doors.setGrip(newGrip);
    }
  }

  /**
   * Set grip height on all sections to the same as on the "donor"
   * @param donor
   * @param newGripHeight
   */
  public setGripHeight(donor: Client.CabinetSection, newGripHeight: number) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.doors.setGripHeight(newGripHeight);
    }
  }

  /**
   * Set possibility to add softclose on all sections to the same as the "donor"
   * @param donor
   * @param canAddSoftClose
   */
  public setAddSoftClose(donor: Client.CabinetSection, addSoftClose: boolean) {
    for (let section of this.cabinetSections) {
      if (section.CabinetType !== donor.CabinetType) {
        continue;
      }

      section.doors.setAddSoftClose(addSoftClose);
    }
  }

  //#endregion Cabinet wide door settings

  public get donorDoor(): Door | undefined {
    let donor;

    this.cabinetSections.forEach((section) => {
      if (section.doors.copyDoor !== undefined) {
        donor = section.doors.doors[section.doors.copyDoor];
      }
    });

    return donor;
  }

  get floorPlanPosition(): Interface_DTO_Draw.Vec3d {
    if (!this._cache.floorPlanPosition) {
      let rotation = FloorPlanHelper.getRotation(this.wall) - Math.PI / 2;
      let posOnWall = VectorHelper.rotate2d(this.item, rotation);
      let posOnFloorPlanTopDown = VectorHelper.add(this.wall.Begin, posOnWall);

      this._cache.floorPlanPosition = {
        X: posOnFloorPlanTopDown.X,
        Y: 0,
        Z: posOnFloorPlanTopDown.Y,
      };
    }
    return this._cache.floorPlanPosition;
  }

  public get floorPlanRotation() {
    return FloorPlanHelper.getRotation(this.wall) - Math.PI / 2;
  }

  public get wall(): Interface_DTO_FloorPlan.WallSection {
    if (!this._cache.wall) {
      for (let w of this.floorPlan.walls) {
        for (let i of w.Items) {
          if (i.CabinetIndex === this.CabinetIndex) {
            this._cache.wall = w;
          }
        }
      }
      if (!this._cache.wall) throw new Error('Could not find wall for cabinet');
    }
    return this._cache.wall;
  }

  public get item(): Interface_DTO_FloorPlan.Item {
    if (!this._cache.item) {
      for (let i of this.wall.Items) {
        if (i.CabinetIndex === this.CabinetIndex) this._cache.item = i;
      }
      if (!this._cache.item)
        throw new Error('Could not find floorPlanItem for cabinet');
    }
    return this._cache.item;
  }

  public get errorInfos(): Client.ErrorInfo[] {
    if (!this._cache.errorInfos) {
      this._cache.errorInfos = CabinetValidationService.validate(this, false);
    }
    return this._cache.errorInfos;
  }

  public get maxErrorLevel(): Interface_Enums.ErrorLevel {
    if (this._cache.maxErrorLevel === undefined) {
      this._cache.maxErrorLevel = Math.max(
        Interface_Enums.ErrorLevel.None,
        ...this.errorInfos.map((info) => info.level),
        ...this.cabinetSections.map((cs) => cs.maxErrorLevel),
      );
    }
    return this._cache.maxErrorLevel;
  }

  public getPoints(
    includeRail: boolean,
    withTolerance: boolean,
  ): Interface_DTO_Draw.Vec2d[] {
    let points = new Array<Interface_DTO_Draw.Vec2d>();

    Enumerable.from(this.cabinetSections)
      .orderBy((c) => c.CabinetSectionIndex)
      .forEach((section) => {
        points.push(...section.getBackPoints(withTolerance));
      });

    Enumerable.from(this.cabinetSections)
      .orderByDescending((c) => c.CabinetSectionIndex)
      .forEach((section) => {
        points.push(...section.getFrontPoints(withTolerance));
      });

    return points;
  }

  public get productLine(): Client.ProductLine {
    if (!this._cache.productLine) {
      this._cache.productLine = Enumerable.from(
        this.editorAssets.productLines,
      ).first((pl) => pl.Id === this.ProductLineId);
    }
    return this._cache.productLine;
  }

  public get minDepth(): number {
    if (this.CabinetType === Interface_Enums.CabinetType.Doors) {
      return 0;
    }
    return this.productLine.MinDepth;
  }
  public get maxDepth(): number {
    return this.productLine.MaxDepth;
  }

  public get actualCabinetSections(): Client.CabinetSection[] {
    // if (!this._cache.actualCabinetSections) {
    //     this._cache.actualCabinetSections = this.cabinetSections.filter(section => {
    //         if (section.CabinetType === Interface_Enums.CabinetType.None)
    //             return false;
    //         if (section.CabinetType === Interface_Enums.CabinetType.SharedItems)
    //             return false;
    //         return true;
    //     });
    // }
    // return this._cache.actualCabinetSections;

    let value = this.cabinetSections.filter((section) => {
      if (section.CabinetType === Interface_Enums.CabinetType.None)
        return false;
      if (section.CabinetType === Interface_Enums.CabinetType.SharedItems)
        return false;
      return true;
    });

    return value;
  }
  public get leftCabinetSection() {
    return this.actualCabinetSections[0];
  }
  public get rightCabinetSection() {
    return this.actualCabinetSections[this.actualCabinetSections.length - 1];
  }
  public get isEmpty(): boolean {
    return this.cabinetSections.every((cabSec) => cabSec.isEmpty);
  }
}
