import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseSnapService } from './BaseSnapService';
import { Cube } from 'app/ts/Interface_DTO_Draw';
/**
 * Helper to provide info about snap positions. Only expected to last from dragStart to dragEnd
 */
export class VerticalDividerSnapService extends BaseSnapService<VerticalDividerSnapService.SnapInfo> {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    return items.length === 1 && items[0].isVerticalDivider;
  }

  private readonly possiblePositions: VerticalDividerSnapService.PositionInfo[];
  private readonly suggestedOffsets: Interface_DTO_Draw.Vec2d[];
  private readonly mouseOffset: Interface_DTO_Draw.Vec2d;

  private _previousRawItemPos?: Interface_DTO_Draw.Vec2d = undefined;

  constructor(
    cabinetSection: Client.CabinetSection,
    item: Client.ConfigurationItem,
    dragStartPoint: Interface_DTO_Draw.Vec2d,
    fallbackSnapService: ISnapInfoService,
  ) {
    super(cabinetSection, item, dragStartPoint, fallbackSnapService);

    this.mouseOffset = VectorHelper.subtract(
      dragStartPoint,
      item as Interface_DTO_Draw.Vec2d,
    );

    this.possiblePositions = this.getPossiblePositions();
    this.suggestedOffsets = this.possiblePositions.map((p) =>
      this.toOffset(p, this.item),
    );
  }

  private getPossiblePositions(): VerticalDividerSnapService.PositionInfo[] {
    let result: VerticalDividerSnapService.PositionInfo[] = [];

    let gables = Enumerable.from(this.cabinetSection.interior.items)
      .where((i) => i.isGable && i !== this.item && !i.isVerticalDivider)
      .orderBy((i) => i.X)
      .toArray();

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _shelfs = Enumerable.from(this.cabinetSection.interior.items)
      .concat(this.cabinetSection.swingFlex.items)
      .where((i) => i.isShelf_19_22 && i.Y < this.cabinetSection.Height - 50)
      .orderBy((i) => i.Y)
      .asEnumerable();

    let cubes: Cube[] = [];

    if (this.cabinetSection.isSwingFlex) {
      let _subAreas = Enumerable.from(
        this.cabinetSection.swingFlex.areas,
      ).selectMany((c) => c.subAreas);

      _subAreas.forEach((_area) => {
        let cube: Cube = {
          X: _area.insideRect.X,
          Y:
            _area.insideRect.Y +
            this.cabinetSection.swingFlex.getCorpusShelfHeight(),
          Height: _area.insideRect.Height,
          Width: _area.insideRect.Width,
          Z: this.cabinetSection.interior.cube.Z,
          Depth: this.cabinetSection.interior.cube.Depth,
        };

        if (
          _shelfs.any(
            (c) =>
              c.centerX > cube.X &&
              c.centerX < cube.X + cube.Width &&
              c.topY >= cube.Y,
          )
        ) {
          cubes.push(cube);
        }
      });

      cubes.forEach((cube) => {
        let _shelfsInCube = _shelfs
          .where(
            (c) =>
              c.centerX > cube.X &&
              c.centerX < cube.X + cube.Width &&
              c.bottomY >= cube.Y &&
              c.topY <= cube.Y + cube.Height,
          )
          .toArray();

        for (let index = 0; index < _shelfsInCube.length; index++) {
          let height =
            this.cabinetSection.interior.cube.Height -
            _shelfsInCube[index].topY;

          if (_shelfsInCube[index + 1]) {
            height = _shelfsInCube[index + 1].Y - _shelfsInCube[index].topY;
          }

          let newCube: Cube = {
            X: _shelfsInCube[index].X,
            Y: _shelfsInCube[index].topY,
            Height: height,
            Width: _shelfsInCube[index].Width,
            Z: this.cabinetSection.interior.cube.Z,
            Depth: this.cabinetSection.interior.cube.Depth,
          };
          cubes.push(newCube);
        }
      });
    } else {
      if (gables.length === 0) {
        cubes.push({ ...this.cabinetSection.interior.cube });
      }

      for (let index = 0; index < gables.length; index++) {
        if (index === 0) {
          let cube: Cube = {
            X: this.cabinetSection.interior.cube.X,
            Y: this.cabinetSection.interior.cube.Y,
            Height: this.cabinetSection.interior.cube.Height,
            Width: gables[index].X - this.cabinetSection.interior.cube.X,
            Z: this.cabinetSection.interior.cube.Z,
            Depth: this.cabinetSection.interior.cube.Depth,
          };
          cubes.push(cube);
          continue;
        }

        let cube: Cube = {
          X: gables[index - 1].rightX,
          Y: gables[index].Y,
          Height: gables[index].Height,
          Width: gables[index].X - gables[index - 1].rightX,
          Z: this.cabinetSection.interior.cube.Z,
          Depth: this.cabinetSection.interior.cube.Depth,
        };
        cubes.push(cube);

        if (index === gables.length - 1) {
          let rightCube: Cube = {
            X: gables[index].rightX,
            Y: gables[index].Y,
            Height: gables[index].Height,
            Width:
              this.cabinetSection.interior.cubeRightX - gables[index].rightX,
            Z: this.cabinetSection.interior.cube.Z,
            Depth: this.cabinetSection.interior.cube.Depth,
          };
          cubes.push(rightCube);
        }
      }

      cubes.forEach((cube) => {
        let _shelfsInCube = _shelfs
          .where((c) => c.centerX > cube.X && c.centerX < cube.X + cube.Width)
          .toArray();

        for (let index = 0; index < _shelfsInCube.length; index++) {
          let height =
            this.cabinetSection.interior.cube.Height -
            _shelfsInCube[index].topY;

          if (_shelfsInCube[index + 1]) {
            height = _shelfsInCube[index + 1].Y - _shelfsInCube[index].topY;
          }

          let newCube: Cube = {
            X: _shelfsInCube[index].X,
            Y: _shelfsInCube[index].topY,
            Height: height,
            Width: _shelfsInCube[index].Width,
            Z: this.cabinetSection.interior.cube.Z,
            Depth: this.cabinetSection.interior.cube.Depth,
          };
          cubes.push(newCube);
        }
      });
    }

    let cubeSplitted: Cube[] = [];

    cubes.forEach((c) => {
      this.splitCubeIntoParts(c, cubeSplitted);
    });

    if (cubeSplitted.length > 0) {
      for (let i = 0; i < cubeSplitted.length; i++) {
        let targetItemPos: VerticalDividerSnapService.PositionInfo = {
          X: cubeSplitted[i].X - this.item.Width / 2,
          Y: cubeSplitted[i].Y,
          Width: this.item.Width,
          Height: 0,
          snapsTo: {
            x: 0,
            width: 0,
            items: [],
          },
        };

        let _bottomShelfsInCube = _shelfs.firstOrDefault(
          (c) =>
            c.leftX < cubeSplitted[i].X &&
            c.rightX > cubeSplitted[i].X &&
            Math.abs(cubeSplitted[i].Y - c.topY) < 100,
        );
        if (!_bottomShelfsInCube) {
          continue;
        }

        let _topShelfsInCube = _shelfs.firstOrDefault(
          (c) =>
            c.leftX < cubeSplitted[i].X &&
            c.rightX > cubeSplitted[i].X &&
            c.bottomY > _bottomShelfsInCube.topY,
        );

        if (_topShelfsInCube) {
          targetItemPos.snapsTo.items.push(_topShelfsInCube);
        }

        if (_bottomShelfsInCube) {
          targetItemPos.snapsTo.items.push(_bottomShelfsInCube);
        }

        if (_topShelfsInCube === _bottomShelfsInCube) {
          continue;
        }

        if (_topShelfsInCube && _bottomShelfsInCube) {
          result.push(targetItemPos);
        }
      }
    }

    return result;
  }

  /**
   * Splits a cube into smaller parts recursively.
   * @param cube The cube to split.
   * @param cubes Array to store the resulting cubes.
   */
  private splitCubeIntoParts(cube: Cube, cubes: Cube[]): void {
    let leftCube = { ...cube };
    let rightCube = { ...cube };

    leftCube.Width = cube.Width / 2;
    rightCube.X = cube.X + cube.Width / 2;
    rightCube.Width = cube.Width / 2;

    if (leftCube.Width > 100 && rightCube.Width > 100) {
      cubes.push(rightCube);

      this.splitCubeIntoParts(leftCube, cubes);
      this.splitCubeIntoParts(rightCube, cubes);
    }
  }

  protected overriddenGetSnapInfo(
    position: Interface_DTO_Draw.Vec2d,
  ): Client.SnapInfo | undefined {
    let rawItemPos = VectorHelper.subtract(position, this.mouseOffset);

    if (!this._previousRawItemPos) {
      this._previousRawItemPos = rawItemPos;
    }

    if (!this.possiblePositions || this.possiblePositions.length === 0) {
      return;
    }

    let sortedSuggestions = Enumerable.from(this.possiblePositions)
      .orderBy((p) => Math.abs(p.X - rawItemPos.X))
      .thenBy((p) => Math.abs(p.Y - rawItemPos.Y));

    let bestSuggestion = sortedSuggestions.first();

    let _dropOffset = {
      ...VectorHelper.subtract(bestSuggestion, this.item),
      Z: 0,
    };

    let _currentPosition: Interface_DTO_Draw.Rectangle = {
      X: position.X,
      Y: position.Y,
      Width: this.item.Width,
      Height: this.item.Height,
    };

    let _toOffset = this.toOffset(_currentPosition, this.item);
    _toOffset.Y = _dropOffset.Y;

    if (Math.abs(_toOffset.X - _dropOffset.X) > this.item.Width) {
      _dropOffset = { ..._toOffset, Z: 0 };
    }

    let _result: VerticalDividerSnapService.SnapInfo = {
      dropOffset: _dropOffset,
      pulloutRestriction: Enums.PulloutWarningSeverity.None,
      rulers: [],
      suggestedItemOffsets: this.suggestedOffsets,
      mirror: false,
      horizontalGuidelineHeights: [],
      snapsTo: bestSuggestion.snapsTo.items,
      snappingItems: [this.item],
    };

    return _result;
  }

  protected overriddenPlace(
    lastSnapInfo: Client.SnapInfo,
  ): [Client.ConfigurationItem] {
    //do nothing, base class handles everything
    return [this.item];
  }

  toOffset(
    position: Interface_DTO_Draw.Rectangle,
    item: Client.ConfigurationItem,
  ): any {
    let _result = VectorHelper.subtract(position, item);
    _result.X = Math.round(_result.X);
    _result.Y = Math.round(_result.Y);
    return _result;
  }
}

export module VerticalDividerSnapService {
  export interface SnapInfo extends Client.SnapInfo {}

  export interface PositionInfo extends Interface_DTO_Draw.Rectangle {
    snapsTo: {
      x: number;
      width: number;
      items: Client.ConfigurationItem[];
    };
  }
}
