import { ProductLineProperties } from './Interface_DTO_ProductLineProperties';
import { DrillingPattern, ProductLineId } from './Interface_Enums';

export const defaultProductLineProperties: ProductLineProperties = {
  SwingFlexMinimumSubsectionInsideWidth: 284,
  SwingFlexMaximumSubsectionInsideWidth: 1168,
  SwingFlexDefaultMaximumSubsectionInsideWidth: 586,
  SwingFlexBottomOffset: 15,
  SwingFlexSpaceForBacking: 25,
  SwingFlexBackingGableOverlap: 7,
  SwingFlexShelfFrontOffset: 1,
  SwingFlexGableLeftProductGroupingNumber: '51010',
  SwingFlexGableRightProductGroupingNumber: '51110',
  SwingFlexGableCenterProductGroupingNumber: '51210',
  SwingFlexMiddlePanelProductGroupingNumber: '51310',
  SwingFlexTopShelfProductGroupingNumber: '5201',
  SwingFlexBottomShelfProductGroupingNumber: '5211',
  SwingFlexPlinthFrontOffset: 22,
  SwingFlexTopShelfOverlap: 18,
  SwingFlexBottomShelfOverlap: 16,
  SwingFlexMovableShelfTopOverlap: 10,
  SwingFlexMovableShelfBottomOverlap: 9,
  SwingFlexDoorSideOverlap: 9,
  SwingFlexDoorGap: 4,
  SwingFlexDoorGripSpace: 35,
  SwingFlexDoorGripDefaultPosition: 1000,
  SwingFlexDoorTopHingeDistanceFromTop: 77,
  SwingFlexDoorTopHingeMaxPosition: 2507,
  SwingFlexDoorHingePositions: [
    { MinDoorHeight: 300, HingePosition: 75 },
    { MinDoorHeight: 759, HingePosition: 523 },
    { MinDoorHeight: 1623, HingePosition: 1419 },
    { MinDoorHeight: 2167, HingePosition: 1963 },
  ],
  FittingPanelSpacerWidth: 78,
  FittingPanelSpacerDepth: 19,
  FittingPanelSpacerOffset: 89.5,
  FittingPanelGableDrillingStartOffset: 43,
  SwingFlexPushToOpenDistance: 700,
  SwingFlexHingeSize: { X: 70, Y: 45, Z: 70 },
  SwingFlexSpaceBehindDoors: 3,
  SwingFlexMiddlePanelHeightAboveShelf: 1,
  DrillingPatterns: [
    { MaxDepthExclusive: 471, DrillingPattern: DrillingPattern._37_261 },
  ],
  DefaultDrillingPattern: DrillingPattern._37_453,
  WidthVariantOption: '22',
};

const default19mmProductLineProperties = {
  ...defaultProductLineProperties,
  SwingFlexMinimumSubsectionInsideWidth: 270,
  SwingFlexBackingGableOverlap: 5.5,
  SwingFlexGableLeftProductGroupingNumber: '31010',
  SwingFlexGableRightProductGroupingNumber: '31110',
  SwingFlexGableCenterProductGroupingNumber: '31210',
  SwingFlexMiddlePanelProductGroupingNumber: '31310',
  SwingFlexTopShelfProductGroupingNumber: '32011',
  SwingFlexBottomShelfProductGroupingNumber: '32111',
  SwingFlexTopShelfOverlap: 15,
  SwingFlexBottomShelfOverlap: 13,
  SwingFlexMovableShelfTopOverlap: 7,
  SwingFlexMovableShelfBottomOverlap: 9,
  SwingFlexDoorSideOverlap: 7.5,
  WidthVariantOption: '19',
};

export const productLinePropertiesByProductLineId: {
  [id: number]: ProductLineProperties | undefined;
} = {
  [ProductLineId.SwingFlex22]: {
    ...defaultProductLineProperties,
  },
  [ProductLineId.SwingFlex19]: {
    ...default19mmProductLineProperties,
  },
  [ProductLineId.Mm19]: {
    ...default19mmProductLineProperties,
    FittingPanelSpacerWidth: 41,
    FittingPanelSpacerDepth: 19,
    FittingPanelSpacerOffset: 20,
  },
  [ProductLineId.Mm19light]: {
    ...default19mmProductLineProperties,
    FittingPanelSpacerWidth: 41,
    FittingPanelSpacerDepth: 19,
    FittingPanelSpacerOffset: 20,
  },
  [ProductLineId.mm19Premium]: {
    ...default19mmProductLineProperties,
    SwingFlexPlinthFrontOffset: 22,
    FittingPanelSpacerWidth: 41,
    FittingPanelSpacerDepth: 19,
    FittingPanelSpacerOffset: 20,
  },
  [ProductLineId.mm19ReferencePlus]: {
    ...default19mmProductLineProperties,
    SwingFlexPlinthFrontOffset: 22,
    FittingPanelSpacerWidth: 41,
    FittingPanelSpacerDepth: 19,
    FittingPanelSpacerOffset: 20,
  },
  [ProductLineId.Mm22]: {
    ...default19mmProductLineProperties,
    SwingFlexMovableShelfBottomOverlap: 9,
    SwingFlexDoorSideOverlap: 7.5,
    FittingPanelSpacerWidth: 38,
    FittingPanelSpacerDepth: 19,
    FittingPanelSpacerOffset: 20,
  },
};
