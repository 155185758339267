import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantHelper from 'app/ts/util/VariantHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { Vec2d } from '../Interface_DTO_Draw';
export class Product implements Interface_DTO.Product {
  public readonly productGroup: Client.Product[];
  private readonly _variants: {
    [productLineId: number]: Client.ProductVariant[];
  } = {};
  private readonly _allVariants: Client.ProductVariant[];
  private readonly _defaultVariants: Client.ProductVariant[];
  private readonly _userSelectableVariants: {
    [productLineId: number]: Client.ProductVariant[];
  } = {};
  private readonly _defaultUserSelectableVariants: Client.ProductVariant[];

  public readonly materials: Client.ProductMaterial[];

  constructor(
    public readonly dto: Readonly<Interface_DTO.Product>,
    variantDict: { [id: number]: Interface_DTO.Variant },
    materials: Client.ProductMaterial[],
    productGroup?: Client.Product[],
    private readonly price?: Interface_DTO.ProductMinimumPrice,
  ) {
    this.productGroup = productGroup || [this];
    let pves = Enumerable.from(dto.ProductVariantExcludes).toDictionary(
      (pve) => pve.VariantId,
    );

    this.materials = this.sortMaterials(materials);

    let pveGroups = Enumerable.from(dto.ProductVariantExcludes).groupBy(
      (pve) => pve.ProductLineId,
    );
    let allGroupIe = pveGroups.firstOrDefault(
      (pveGroup) => pveGroup.key() === null,
    );
    let allGroup = allGroupIe
      ? allGroupIe
      : Enumerable.from<Interface_DTO.ProductVariantExclude>([]);

    let variantGroups = pveGroups
      .where((pveGroup) => pveGroup.key() !== null)
      .select((pveGroup) => {
        let variants: Client.ProductVariant[] =
          dto.ProductVariants.map<Client.ProductVariant>((pv) => {
            let variant = variantDict[pv.VariantId];
            let result: Client.ProductVariant;
            result = {
              ...variant,
              VariantOptions: variant.VariantOptions.filter(
                (vo) =>
                  pv.ProductVariantOptionExcludes.map(
                    (pvoe) => pvoe.VariantOptionId,
                  ).indexOf(vo.Id) < 0,
              ),
              OnlyAdmin: pv.OnlyAdmin,
            };

            let pve = pveGroup.firstOrDefault(
              (_pve) => _pve.VariantId === pv.VariantId,
            );
            if (!pve) {
              pve = allGroup.firstOrDefault(
                (_pve) => _pve.VariantId === pv.VariantId,
              );
            }
            if (pve) {
              let variantOption = result.VariantOptions.filter(
                (vo) => vo.Number === pve!.Value,
              )[0];
              result.VariantOptions = variantOption ? [variantOption] : [];
              result.OnlyAdmin = true;
              result.ProductVariantExclude = pve;
            }

            return result;
          });
        return {
          variants,
          productLineId: pveGroup.key(),
        };
      });

    variantGroups.forEach((vg) => {
      if (!vg.productLineId) return;
      this._variants[vg.productLineId] = vg.variants;
    });

    this._defaultVariants = dto.ProductVariants.map((pv) => {
      let variant = variantDict[pv.VariantId];
      let result: Client.ProductVariant;
      result = {
        ...variant,
        VariantOptions: variant.VariantOptions.filter(
          (vo) =>
            pv.ProductVariantOptionExcludes.map(
              (pvoe) => pvoe.VariantOptionId,
            ).indexOf(vo.Id) < 0,
        ),
        OnlyAdmin: pv.OnlyAdmin,
      };
      let pve = allGroup.firstOrDefault(
        (_pve) => _pve.VariantId === pv.VariantId,
      );
      if (pve) {
        let variantOption = result.VariantOptions.filter(
          (vo) => vo.Number === pve!.Value,
        )[0];
        result.VariantOptions = variantOption ? [variantOption] : [];
        result.OnlyAdmin = true;
        result.ProductVariantExclude = pve;
      }
      return result;
    });

    //User selectable variants
    for (let pg in this._variants) {
      this._userSelectableVariants[pg] = this._variants[pg].filter((pv) =>
        VariantHelper.isUserSelectable(pv),
      );
    }
    this._defaultUserSelectableVariants = this._defaultVariants.filter((pv) =>
      VariantHelper.isUserSelectable(pv),
    );

    this._allVariants = Enumerable.from(dto.ProductVariants)
      .groupBy((pv) => pv.VariantId)
      .select((pvGroup) => {
        let r = {
          ...pvGroup.first(),
          ...variantDict[pvGroup.key()],
        };
        r.OnlyAdmin = pvGroup.all((pv) => pv.OnlyAdmin);
        return r;
      })
      .toArray();
  }

  public getUserSelectableVariants(
    productLineId: Interface_Enums.ProductLineId,
  ): Client.ProductVariant[] {
    let result = this._userSelectableVariants[productLineId];
    if (!result) {
      result = this._defaultUserSelectableVariants;
    }
    return result;
  }
  public productCategory?: Client.ProductCategory;

  public getVariants(
    productLineId: Interface_Enums.ProductLineId,
  ): Client.ProductVariant[] {
    let result = this._variants[productLineId];
    if (!result) {
      result = this._defaultVariants;
    }
    return result;
  }

  public getAllVariants(): Client.ProductVariant[] {
    return this._allVariants;
  }

  // #region DTO mappings

  get AddToProductNo() {
    return this.dto.AddToProductNo;
  }

  get AddVariant1() {
    return this.dto.AddVariant1;
  }

  get AddVariant2() {
    return this.dto.AddVariant2;
  }

  get AddVariant3() {
    return this.dto.AddVariant3;
  }

  get DefaultName2() {
    return this.dto.DefaultName2;
  }

  get Id() {
    return this.dto.Id;
  }

  get IsTemplate() {
    return this.dto.IsTemplate;
  }

  get MainModelNumber() {
    return this.dto.MainModelNumber;
  }

  get Name() {
    return this.dto.Name;
  }

  get OrgName() {
    return this.dto.OrgName;
  }

  get ParentCategoryId() {
    return this.dto.ParentCategoryId;
  }

  get PossibleMaterialIds() {
    return this.dto.PossibleMaterialIds;
  }

  get ProductDataList() {
    return this.dto.ProductDataList;
  }

  get ProductGroupingName() {
    return this.dto.ProductGroupingName;
  }

  get ProductGroupingNo() {
    return this.dto.ProductGroupingNo;
  }

  get ProductNo() {
    return this.dto.ProductNo;
  }

  get ProductType() {
    return this.dto.ProductType;
  }

  get ProductVariants() {
    return this.dto.ProductVariants;
  }

  get SortOrder() {
    return this.dto.SortOrder;
  }

  get Unit() {
    return this.dto.Unit;
  }

  get ProductLineIds() {
    return this.dto.ProductLineIds;
  }

  get ModuleItems() {
    return this.dto.ModuleItems;
  }

  get ProductionLeadTime() {
    return this.dto.ProductionLeadTime;
  }

  get ProductVariantExcludes() {
    return this.dto.ProductVariantExcludes;
  }

  //BaseEntity

  get Enabled() {
    return this.dto.Enabled;
  }

  /**
   * False if current store has a license for the product
   * */
  get OverrideChain() {
    if (App.debug.disableAllProducts) {
      return true;
    } else if (App.debug.enableAllProducts) {
      return false;
    }
    return this.dto.OverrideChain;
  }

  // #endregion DTO Mappings

  get isNone(): boolean {
    return !this.dto || this.dto.Id <= 0;
  }

  /**
   * True if current store does not have a license for the product
   * */
  get overrideChain() {
    if (App.debug.disableAllProducts) {
      return true;
    } else if (App.debug.enableAllProducts) {
      return false;
    }
    return this.dto && this.dto.OverrideChain;
  }

  get parentCategoryId(): number {
    return this.dto ? this.dto.ParentCategoryId : 0;
  }

  get picturePath(): string | null {
    if (!this.ProductDataList) return null;
    if (this.ProductDataList.length < 1) return null;
    if (this.ProductDataList[0].PicturePath === '') return null;
    return this.ProductDataList[0].PicturePath;
  }

  get model3dPath(): string | null {
    if (!this.ProductDataList) return null;
    if (this.ProductDataList.length < 1) return null;
    if (this.ProductDataList[0].Model3DPath === '') return null;
    return this.ProductDataList[0].Model3DPath;
  }

  get model3dWidth(): number | null {
    if (!this.ProductDataList) return null;
    if (this.ProductDataList.length < 1) return null;
    let pd = this.ProductDataList[0];
    if (pd.Model3DWidth > 0) {
      return pd.Model3DWidth;
    } else {
      return pd.DefaultWidth;
    }
  }
  get model3dDepth(): number | null {
    if (!this.ProductDataList) return null;
    if (this.ProductDataList.length < 1) return null;
    let pd = this.ProductDataList[0];
    if (pd.Model3DWidth > 0) {
      return pd.Model3DDepth;
    } else {
      return pd.DefaultDepth;
    }
  }

  public getModel2DPath(
    materialId?: number | null,
    frameMaterialId?: number | null,
    size?: Vec2d,
  ): string | null {
    let productData = this.getProductData(materialId ?? undefined);
    if (!productData) {
      productData = this.getProductData();
    }

    if (productData && productData.Model2DPath) {
      let result = 'api/productImage/' + this.Id;
      let urlP = new URLSearchParams();
      if (materialId && materialId > -1) {
        urlP.append('materialId', materialId.toString());
      }
      if (frameMaterialId && frameMaterialId > -1) {
        urlP.append('frameMaterialId', frameMaterialId.toString());
      }
      if (size) {
        urlP.append('x', size.X.toString());
        urlP.append('y', size.Y.toString());
      }

      // update cacheVersion to clear all cached versions of productImage.
      // this parameter is not used for anything serverside
      // it is only here to confuse the caching mechanism in the browser.
      const cacheVersion = 2;
      urlP.append('cv', cacheVersion.toString());
      result = result + '?' + urlP.toString();
      return result;
    } else {
      return null;
    }
  }

  public isAvailableInWidth(
    width: number,
    allowAllProducts: boolean,
    productLineId: Interface_Enums.ProductLineId,
    allowAllMaterials: boolean,
    materialId: number,
  ): boolean {
    for (let product of this.productGroup) {
      if (!allowAllProducts) {
        if (product.overrideChain) continue;
        if (product.ProductLineIds.indexOf(productLineId) < 0) continue;
      }
      let matIds = product.PossibleMaterialIds;
      if (matIds.length > 0) {
        if (!allowAllMaterials)
          matIds = matIds.filter((mat) => mat.IsEnabled && !mat.IsOverride);

        if (!matIds.some((matId) => matId.Id === materialId)) continue;
      }

      if (ProductHelper.isFlexWidth(product)) {
        if (
          ProductHelper.minWidth(product) <= width &&
          width <= ProductHelper.maxWidth(product)
        )
          return true;
      } else {
        if (ProductHelper.defaultWidth(product) === width) return true;
      }
    }
    return false;
  }

  public isAvailableInHeight(
    height: number,
    allowAllProducts: boolean,
    productLineId: Interface_Enums.ProductLineId,
    allowAllMaterials: boolean,
    materialId: number,
  ): boolean {
    for (let product of this.productGroup) {
      if (!allowAllProducts) {
        if (product.overrideChain) continue;
        if (product.ProductLineIds.indexOf(productLineId) < 0) continue;
      }
      let matIds = product.PossibleMaterialIds;
      if (matIds.length > 0) {
        if (!allowAllMaterials)
          matIds = matIds.filter((mat) => mat.IsEnabled && !mat.IsOverride);

        if (!matIds.some((matId) => matId.Id === materialId)) continue;
      }

      if (ProductHelper.isFlexHeight(product)) {
        if (
          ProductHelper.minHeight(product) <= height &&
          height <= ProductHelper.maxHeight(product)
        )
          return true;
      } else {
        if (ProductHelper.defaultHeight(product) === height) return true;
      }
    }
    return false;
  }

  public isAvailableInDepth(
    depth: number,
    allowAllProducts: boolean,
    productLineId: Interface_Enums.ProductLineId,
    allowAllMaterials: boolean,
    materialId: number,
  ): boolean {
    for (let product of this.productGroup) {
      if (!allowAllProducts) {
        if (product.overrideChain) continue;
        if (product.ProductLineIds.indexOf(productLineId) < 0) continue;
      }
      let matIds = product.PossibleMaterialIds;
      if (matIds.length > 0) {
        if (!allowAllMaterials)
          matIds = matIds.filter((mat) => mat.IsEnabled && !mat.IsOverride);

        if (!matIds.some((matId) => matId.Id === materialId)) continue;
      }

      if (ProductHelper.isFlexDepth(product, productLineId)) {
        if (
          ProductHelper.minDepth(product, productLineId) <= depth &&
          depth <= ProductHelper.maxDepth(product, productLineId)
        )
          return true;
      } else {
        if (ProductHelper.defaultDepth(product, productLineId) === depth)
          return true;
      }
    }
    return false;
  }

  public supportsDimensions(
    dimensions: Interface_DTO.Vec3d,
    productLineId: Interface_Enums.ProductLineId,
    materialId: number,
    _options: Partial<{
      checkMaxDepth: boolean;
    }> = {},
  ): boolean {
    const defaultOptions = { checkMaxDepth: true };
    const options = { ...defaultOptions, ..._options };
    let material = this.materials.find((mat) => mat.Id === materialId)?.dto;

    // check width
    if (ProductHelper.defaultWidth(this, material) !== dimensions.X) {
      if (!ProductHelper.isFlexWidth(this)) {
        return false;
      }
      if (ProductHelper.minWidth(this, material) > dimensions.X) {
        return false;
      }
      if (ProductHelper.maxWidth(this, material) < dimensions.X) {
        return false;
      }
    }

    // check height
    if (ProductHelper.defaultHeight(this, material) !== dimensions.Y) {
      if (!ProductHelper.isFlexHeight(this, productLineId)) {
        return false;
      }
      if (ProductHelper.minHeight(this, material) > dimensions.Y) {
        return false;
      }
      if (ProductHelper.maxHeight(this, material) < dimensions.Y) {
        return false;
      }
    }

    // check depth
    if (
      ProductHelper.defaultDepth(this, productLineId, material) !== dimensions.Z
    ) {
      if (
        ProductHelper.minDepth(this, productLineId, material) > dimensions.Z
      ) {
        return false;
      }
      if (
        options.checkMaxDepth &&
        ProductHelper.maxDepth(this, productLineId, material) < dimensions.Z
      ) {
        return false;
      }
    }

    //all checks passed
    return true;
  }

  public getProductData(
    materialId?: number,
  ): Interface_DTO.ProductData | undefined {
    if (!this.ProductDataList) return undefined;
    for (let pd of this.ProductDataList) {
      if (
        pd.MaterialId === materialId ||
        (pd.MaterialId === -1 && materialId === undefined)
      ) {
        return pd;
      }
    }
    return undefined;
  }

  public hasWidth22(productLine: number): boolean {
    return this.getVariants(productLine).some(
      (v) => v.Number === VariantNumbers.WidthRight,
    );
  }

  public hasWidthTotal2(productLine: number): boolean {
    return this.getVariants(productLine).some(
      (v) => v.Number === VariantNumbers.WidthTotal,
    );
  }

  public hasDepth22(productLine: number): boolean {
    return this.getVariants(productLine).some(
      (v) => v.Number === VariantNumbers.DepthRight,
    );
  }

  public heightReductionAllowed2(productLine: number): boolean {
    return this.getVariants(productLine).some(
      (v) => v.Number === VariantNumbers.Trimming,
    );
  }

  public useActualHeight2(productLine: number): boolean {
    return this.getVariants(productLine).some(
      (v) => v.Number === VariantNumbers.UseActualHeight,
    );
  }

  public canBeJoined2(productLine: number): boolean {
    return this.getVariants(productLine).some(
      (v) => v.Number === VariantNumbers.JointPosition,
    );
  }

  public hasOnlyDisabledMaterials(): boolean {
    if (!this.materials) return false;
    if (this.materials.length === 0) return false;
    return this.materials.every((mat) => mat.isOverride || mat.isDiscontinued);
  }

  private _minPrice: number | null | undefined = undefined;
  public get minPrice(): number | null {
    if (this._minPrice === undefined) {
      this._minPrice = this.getMinPrice();
    }
    return this._minPrice;
  }

  private getMinPrice(): number | null {
    const groupPrices = this.productGroup
      .map((product) => product.price)
      .filter((price) => !!price);
    if (groupPrices.length === 0) return null;
    return Math.min(...groupPrices.map((price) => price!.PriceInclVat));
  }

  private sortMaterials(mats: Client.ProductMaterial[]) {
    let result = [...mats];
    result.sort((m1, m2) => m1.SortOrder - m2.SortOrder);
    return result;
  }

  public toString() {
    let description = `${this.ProductNo} ${this.Name}`;
    let pd = this.ProductDataList?.[0];
    if (pd) {
      let width =
        pd.MinWidth === pd.MaxWidth
          ? pd.MinWidth.toString()
          : `${pd.MinWidth}-${pd.MaxWidth}`;
      let height =
        pd.MinHeight === pd.MaxHeight
          ? pd.MinHeight.toString()
          : `${pd.MinHeight}-${pd.MaxHeight}`;
      let depth =
        pd.MinDepth === pd.MaxDepth
          ? pd.MinDepth.toString()
          : `${pd.MinDepth}-${pd.MaxDepth}`;
      return `${width} ${height} ${depth} ${description}`;
    }
    return description;
  }
}
